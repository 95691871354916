(function () {
        "use strict";

        window.app = window.app || {};

        var allDatatable = [];

        /** ================================================================================== **/
        /** ================================== General ====================================== **/
        /** ================================================================================== **/

        /**
         * Init base layout
         */
        app.init = function () {
            app.initAjaxForm(); // Init Ajax Form
            app.initConfirm(); // Init Confirm
            app.initFlashMessages(); // Flash messages
            app.initDataTables(); // Datatables
            app.initDatepicker(); //Date picker
            app.initIcheck(); // iCheck
            app.initSelect2(); // Select2
            app.initCalendar(); // Calendar
            app.initFloatLabels(); //float-labels-js
        };

        /**
         * Init FloatLabels
         */
        app.initFloatLabels = function () {
            new FloatLabels('form');
        };

        /**
         * Init Collapse Bootstrap
         *
         * @param {string} id
         */
        app.initCollapse = function (id) {
            var $myGroup = $('#' + id);
            $myGroup.on('show.bs.collapse', '.collapse', function () {
                $myGroup.find('.collapse.in').collapse('hide');
            });
        };

        /**
         * Init Flash Messages
         */
        app.initFlashMessages = function () {
            $('.flash-messages').hide().removeClass('hidden').delay(1000).fadeIn(600).delay(6000).fadeOut(600);

            $('.flash-messages .close').unbind('click');
            $('.flash-messages .close').bind('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).parent().fadeOut(300);
            });
        };

        /**
         * Init Ajax form
         */
        app.initAjaxForm = function () {
            $('a.modal-ajax-form').unbind('click');
            $('a.modal-ajax-form').bind('click', function (e) {
                e.preventDefault();
                var url = $(this).attr('href');
                var title = $(this).attr('data-title') ? $(this).attr('data-title') : null;
                var dataFormNoValidation = $(this).attr('data-modal-validation') ? $(this).attr('data-modal-validation') : null;
                var confirmation = $(this).attr('data-submit-confirmation-text') ? $(this).attr('data-submit-confirmation-text') : '';
                var modalType = $(this).attr('data-modal-type') ? $(this).attr('data-modal-type') : null;
                const isAutoFillCityDisabled = $(this).attr('data-auto-fill-city') || null;

                $.ajax({
                    url: url,
                    method: 'POST',
                    success: function (data, textStatus, xhr) {
                        var footer = '<button type="button" class="btn btn-default" data-dismiss="modal" >Retour</button>';

                        if (dataFormNoValidation == 1 && modalType == null) {
                            footer += '<button type="button" class="btn btn-success admin-modal_submit">Valider</button>';
                        }

                        if (modalType === 'medical_control') {
                            footer += '<button type="button" class="btn btn-info admin-modal_submit" name="submit" value="save">Enregistrer</button>';
                        }

                        app.createModal(title, data, true, footer, true, isAutoFillCityDisabled);

                        // Seulement pour l'étape contrôle organisation
                        var thirdArgCallCityAjax = true;
                        var $sectionOrganisationControle = $('section.form-control-organisation');
                        if ($sectionOrganisationControle.length > 0) {
                            thirdArgCallCityAjax = false;
                        }

                        initAjaxForm(confirmation, thirdArgCallCityAjax);
                    },
                    error: function (xhr, textStatus, codeError) {
                        var text = 'Problème de chargement';
                        if (codeError === 'Forbidden') {
                            text = 'Vous n\'avez pas accès à cette page';
                        }

                        app.addFlashError(text);
                    }
                });
            });

            //Date picker
            $('.date-picker').datepicker({
                autoclose: true,
                language: 'fr',
            });

            $('.date-picker-month').datepicker({
                autoclose: true,
                language: 'fr',
                format: "mm-yyyy",
                startView: "months",
                minViewMode: "months"
            });

            // iCheck
            app.initIcheck();
        };

        /**
         * Init iCheck
         */
        app.initIcheck = function () {
            $('.icheck:not(.done)').iCheck({
                cursor: true,
                labelHover: true,
                labelHoverClass: 'label-icheck',
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
                increaseArea: '20%' // optional
            });

            $('.icheck').addClass('done');
        };


        /**
         * Init Calendar
         */
        app.initCalendar = function () {
            const urlGetAbsences = $('#box-doctor-calendar').attr('data-url-get-absence');

            $.ajax({
                type: "GET",
                url: urlGetAbsences,
                success: function (data) {
                    const calendar = $('#editCalendar').fullCalendar({
                        header: {center: 'month,agendaWeek'},
                        events: data,
                        select: function (start, end) {
                            const url = $('#box-doctor-calendar').attr('data-url-add-absence');
                            $.ajax({
                                type: "GET",
                                url: url,
                                success: function (data) {
                                    app.createModal("Créer une nouvelle absence", data, true, "<button type=\"submit\" class=\"btn btn-success\">Valider</button>");
                                    $('.start-period').val(start.format('L') + ' ' + moment().format('LT'));
                                    $('.end-period').val(end.format('L') + ' ' + moment().format('LT'));
                                    $('.btn-success').on('click', function (e) {
                                        e.preventDefault();
                                        $.ajax({
                                            type: "POST",
                                            url: url,
                                            data: $('.form-absence').serialize(),
                                            success: function (data) {
                                                app.processAfterAjaxResponse(data);
                                                $('.modal-body').html(data);
                                                app.initAjaxForm();
                                                app.initDatepicker();
                                                app.initDatetimePicker()
                                            }
                                        });
                                        var pathSpinnerImg = $('#box-doctor-calendar').attr('spinner-url');
                                        $('.modal-body').html('<img src="' + pathSpinnerImg + '" width="50px" height="50px" />');
                                    });
                                }
                            });
                        },
                        eventClick: function (event, jsEvent, view) {
                            const urlEdit = $('#box-doctor-calendar').attr('data-url-edit-absence') + event.id;
                            $.ajax({
                                type: "GET",
                                url: urlEdit,
                                success: function (data) {
                                    app.createModal("Modifier une absence", data, true, "<button type=\"submit\" class=\"btn btn-success\">Valider</button>");

                                    $('.btn-success').on('click', function (e) {
                                        e.preventDefault();
                                        $.ajax({
                                            type: "POST",
                                            url: urlEdit,
                                            data: $('.form-absence').serialize(),
                                            success: function (data) {
                                                app.processAfterAjaxResponse(data);
                                                $('.modal-body').html(data);
                                                app.initAjaxForm();
                                                app.initDatepicker();
                                                app.initDatetimePicker()
                                            }
                                        });
                                        var pathSpinnerImg = $('#box-doctor-calendar').attr('spinner-url');
                                        $('.modal-body').html('<img src="' + pathSpinnerImg + '" width="50px" height="50px" />');
                                    })

                                    $('.btn-remove-abs').on('click', function (e) {
                                        e.preventDefault();
                                        $.ajax({
                                            type: "GET",
                                            url: $(this).attr('data-url'),
                                            success: function (data) {
                                                app.processAfterAjaxResponse(data);
                                            }
                                        });
                                    });
                                }
                            });
                        },
                        selectable: true,
                        selectHelper: true,
                        eventLimit: true
                    });

                    $('#editCalendar .datepicker').addClass('done');

                    const urlEditPlanningHours = $('#box-doctor-calendar').attr('data-url-edit-planning-hour');
                    $('.btn-edit-planning-hour').on('click', function (e) {
                        e.preventDefault();
                        $.ajax({
                            type: "GET",
                            url: urlEditPlanningHours,
                            success: function (data) {
                                app.createModal("Modifier les horaires de cabinet", data, true, "<button type=\"submit\" class=\"btn btn-success\">Valider</button>");

                                $('.btn-success').on('click', function (e) {
                                    e.preventDefault();
                                    $.ajax({
                                        type: "POST",
                                        url: urlEditPlanningHours,
                                        data: $('.form-planning-hours').serialize(),
                                        success: function (data) {
                                            app.processAfterAjaxResponse(data);
                                            $('.modal-body').html(data);
                                            app.initAjaxForm();
                                            app.initDatepicker();
                                        }
                                    });
                                    var pathSpinnerImg = $('#box-doctor-calendar').attr('spinner-url');
                                    $('.modal-body').html('<img src="' + pathSpinnerImg + '" width="50px" height="50px" />');
                                });
                            }
                        });
                    })
                }
            });
        };


        /**
         * Init Timepicker
         */
        app.initTimePicker = function () {
            $('input.timepicker').datetimepicker({
                locale: 'fr',
                format: 'HH:mm'
            })
                .on('dp.change', function (e) {
                    e.target.parentNode.classList.add('fl-is-active');
                    e.target.focus();
                });
        };

        /**
         * Init Datetimepicker
         */
        app.initDatetimePicker = function () {
            $('input.datetimepicker').datetimepicker({
                locale: 'fr'
            })
                .on('dp.change', function (e) {
                    e.target.parentNode.classList.add('fl-is-active');
                    e.target.focus();
                });
        };

        /**
         * Replace string characters by empty for .only-numbers fields
         */
        app.initPreventStringCharacters = function () {
            $('.only-numbers').on('input', function (event) {
                this.value = this.value.replace(/[^0-9]/g, '');
            });
        };

        /**
         * Autocomplete city from zip
         * @param {bool} executeInitPickCorrectCity
         * @param {bool} pickCorrectCityVal
         * @param {bool} stopRequest
         */
        app.initAutocompleteCityFromZip = function (executeInitPickCorrectCity, pickCorrectCityVal, stopRequest) {
            if (executeInitPickCorrectCity === undefined || executeInitPickCorrectCity === null || executeInitPickCorrectCity === '') {
                executeInitPickCorrectCity = true;
            }
            if (pickCorrectCityVal === undefined || pickCorrectCityVal === null || pickCorrectCityVal === '') {
                pickCorrectCityVal = false;
            }
            if (stopRequest === undefined || stopRequest === null || stopRequest === '') {
                stopRequest = true;
            }

            var ajaxReq = 'cancelPrevRequest';
            var $zipInput = $('input.zip');
            var $abortRequest;

            $zipInput.unbind();
            $zipInput.on('keyup', function (event) {
                var $fieldZip = $(this);
                var url = $fieldZip.attr('data-url');
                var zip = $fieldZip.val();
                var $inputCity = $fieldZip.closest('.container-address').find('.city');
                var initialPlaceholderCity = 'Choisir une ville';
                if (zip.length > 3) {

                    ajaxReq = $.ajax({
                        url: url,
                        method: 'POST',
                        data: {
                            q: zip,
                            autocomplete_from_zip: true
                        },
                        beforeSend: function () {
                            $inputCity
                                .attr('disabled', 'disabled')
                                .css('background-color', 'lightgrey')
                                .find('option:first').text('Chargement...')
                            ;

                            if (stopRequest) {
                                $abortRequest = false;
                                if (ajaxReq !== 'cancelPrevRequest' && ajaxReq.readyState < 4) {
                                    $abortRequest = true;
                                    ajaxReq.abort();
                                }
                            }
                        },
                        success: function (data, textStatus, xhr) {
                            $('.city-list').remove();

                            $inputCity.empty();
                            $inputCity.append(
                                '<option value="-1">Choisir une ville</option>'
                            );
                            for (var index in data) {
                                $inputCity.append(
                                    '<option value="' + data[index].id + '">' + data[index].name + '</option>'
                                )
                            }

                            if (pickCorrectCityVal) {
                                $inputCity.val($fieldZip.closest('.container-address').attr('data-id-city'));
                                if ($inputCity.val() == '' || $inputCity.val() == null) {
                                    var unmappedCity = $fieldZip.closest('.container-address').find('.unmapped-city').val();
                                    if (unmappedCity != null && unmappedCity != '' && unmappedCity != undefined) {
                                        $inputCity.val(unmappedCity);
                                    } else {
                                        $inputCity.val($inputCity.find('option:first').val());
                                    }
                                }
                            }

                            $inputCity
                                .removeAttr('disabled')
                                .css('background-color', '#fff')
                                .find('option:first').text(initialPlaceholderCity)
                            ;

                            var defaultIdCitySelected = $fieldZip.closest('.container-address').attr('data-id-city-selected');
                            if (defaultIdCitySelected != null && defaultIdCitySelected != '' && defaultIdCitySelected != undefined) {
                                $inputCity.val(defaultIdCitySelected);
                            }
                        },
                        error: function (xhr, textStatus, codeError) {
                            if (!$abortRequest) {
                                app.addFlashError('Problème de chargement');

                                $inputCity
                                    .removeAttr('disabled')
                                    .css('background-color', '#fff')
                                    .find('option:first').text(initialPlaceholderCity)
                                ;
                            }
                        }
                    });
                }
            });

            if (executeInitPickCorrectCity) {
                app.initPickCorrectCity();
            }
        };

        app.initPickCorrectCity = function () {
            var $zipInput = $('input.zip');
            $zipInput.each(function () {
                $(this).closest('.container-address').find('.city').val($(this).closest('.container-address').find('.unmapped-city').val());
            });
        };

        /**
         *
         * @param {jQuery} $inputCity
         */
        app.disableSelectCity = function ($inputCity) {
            $inputCity
                .addClass('select-city-disabled')
                .attr('disabled', 'disabled')
                .find('option:first').text('Chargement...')
            ;
        };

        /**
         *
         * @param {jQuery} $inputCity
         * @param {string} initialPlaceholderCity
         */
        app.initSelectCity = function ($inputCity, initialPlaceholderCity) {
            $inputCity
                .removeClass('select-city-disabled')
                .removeAttr('disabled')
                .find('option:first').text(initialPlaceholderCity)
            ;
        };

        /**
         * Auto fill date/datetime field on click icon
         */
        app.initFieldsDatesOnClickIcon = function () {
            $('.input-group-addon.date').on('click', function (event) {
                var $field = $(this).closest('.input-group').find('input');
                var date = new Date();
                var month = ('0' + (date.getMonth() + 1)).slice(-2);
                var day = ('0' + (date.getDate())).slice(-2);

                $field.val(day + '/' + month + '/' + date.getFullYear());

                app.initCheckDates($field);
            });

            $('.input-group-addon.datetime').on('click', function (event) {
                var $field = $(this).closest('.input-group').find('input');
                var date = new Date();
                var month = ('0' + (date.getMonth() + 1)).slice(-2);
                var time = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
                var day = ('0' + (date.getDate())).slice(-2);

                $field.val(day + '/' + month + '/' + date.getFullYear() + ' ' + time);
            });

            $('.input-group-addon.time').on('click', function (event) {
                var $field = $(this).closest('.input-group').find('input');
                var date = new Date();
                var time = ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);

                $field.val(time);
            });
        };

        /**
         * Proocess after return of return AjaxRepsonse (execute callbacks, etc...)
         *
         * @param {json|html} json
         * @param selector
         */
        app.processAfterAjaxResponse = function (json, selector) {
            if (typeof json === 'object') {
                // Callbacks à exécuter
                if (json.data.hasOwnProperty('callbacks') && Array.isArray(json.data.callbacks)) {
                    for (var i = 0; i < json.data.callbacks.length; i++) {
                        if (json.data.hasOwnProperty('arguments') && Array.isArray(json.data.arguments)) {
                            window.app[json.data.callbacks[i]](json.data.arguments[i]);
                        }
                    }
                }

                // Datatables à recharger
                // TODO : Désactivation de jshint à voir avec Patrice
                // TODO : Warning : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
                if (json.data.hasOwnProperty('datatable_ids') && Array.isArray(json.data.datatable_ids)) {
                    for (var j = 0; j < json.data.datatable_ids.length; j++) {
                        var idDatatable = json.data.datatable_ids[j];
                        var uniqid = $('#' + idDatatable).attr('id-datatable');
                        var datatableObject = allDatatable[parseInt(uniqid)];
                        datatableObject.ajax.reload(function () {
                            if (json.data.hasOwnProperty('datatable_callbacks_after_reload') && Array.isArray(json.data.datatable_callbacks_after_reload)) {
                                for (var k = 0; k < json.data.datatable_callbacks_after_reload.length; k++) {
                                    window.app[json.data.datatable_callbacks_after_reload[k]]();
                                }
                            }
                        });
                    }
                }

                // Messages flash
                if (json.data.hasOwnProperty('flash_type') && json.data.hasOwnProperty('flash_message')) {
                    app.addFlash(json.data.flash_type, json.data.flash_message);
                }
            } else { // c'est de l'HTML
                if (selector) {
                    selector.html(json);

                    app.initDatepicker(); //Date picker
                    app.initIcheck(); // iCheck
                    app.initFloatLabels(); //float-labels-js
                    app.initSelect2(); // Select2
                    app.initBootstrapSlider();
                    app.initDoctorForm();
                    app.initFormVaccinationEstimateNeed('bloc-prototype-collection');
                }
            }
        };

        /**
         * Init alert confirmaion
         */
        app.initConfirm = function () {
            $('.confirm').unbind('click');
            $('.confirm').bind('click', function (e) {
                var message = "Etes vous sur de vouloir supprimer cet élément ?";
                if ($(this).attr('data-message')) {
                    message = $(this).attr('data-message');
                }

                if (confirm(message) == false) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    // Appel en AJAX de l'URL du lien
                    if ($(this).hasClass('action-ajax')) {
                        e.preventDefault();
                        $.ajax({
                            url: $(this).attr('href'),
                            method: 'GET',
                            success: function (json, textStatus, xhr) {
                                app.processAfterAjaxResponse(json);
                            },
                            error: function (xhr, textStatus, codeError) {
                                app.addFlashError('Problème de chargement');
                            }
                        });
                    }
                }
            });
        };

        /**
         * Show current menu in side menu bar
         *
         * @param {string} route
         */
        app.activateMenu = function (route) {
            var elementSelector = ".main-sidebar .sidebar-menu li a[href='" + window.location.pathname + "']";
            var element = $(elementSelector);

            if (element.length == 0) {
                elementSelector = ".main-sidebar .sidebar-menu li a[data-route*='" + route + "']";
            }

            $(elementSelector).parent('li').addClass('active');
            $(elementSelector).parent('li').closest('ul.treeview-menu').slideDown(1000);
            $(elementSelector).parent('li').closest('.treeview').addClass('menu-open');
            $(elementSelector).prepend('<span class="sr-only">(current)</span>');
            $(elementSelector).parents('.dropdown').addClass('active');
        };

        /**
         * Add error flash message
         *
         * @param {string} message
         */
        app.addFlashError = function (message) {
            app.addFlash('error', message);
        };

        /**
         * Add flash message
         *
         * @param {string} type
         * @param {string} message
         */
        app.addFlash = function (type, message) {
            $('#flash_' + type + ' .flash-message').html(message);
            $('.flash-messages').css('display', 'block').find('#flash_' + type).removeClass('hidden').delay(1000).fadeIn(600).delay(6000).fadeOut(600);

            if (type == 'success') {
                $('#flash_error').css('display', 'none');
            } else if (type == 'error') {
                $('#flash_success').css('display', 'none');
            }
        };

        /**
         * Create Modal
         *
         * @param {string} title
         * @param {string} body
         * @param {string} lg
         * @param {string} footer
         * @param {bool} footerShow
         */
        app.createModal = function (title, body, lg, footer, footerShow, isAutoFillCityDisabled) {
            footerShow = footerShow == undefined ? true : footerShow;
            lg = lg == undefined ? false : lg;

            if (lg) {
                $('#admin-modal .modal-dialog').addClass('modal-lg');
            } else {
                $('#admin-modal .modal-dialog').removeClass('modal-lg');
            }

            if (title) {
                $('#admin-modal .modal-title').html(title);
                $('#admin-modal .modal-header').removeClass('hidden');
            } else {
                $('#admin-modal .modal-header').addClass('hidden');
            }

            $('#admin-modal .modal-body').html(body);
            if (footerShow) {
                if (footer) {
                    $('#admin-modal .modal-footer').html(footer);
                }
            }

            // Initialisation et affichage de la modal avec désactivation de la fermeture par clic extérieur et clavier (touche echap)
            $('#admin-modal').modal({
                backdrop: 'static',
                keyboard: false
            })
                .modal('show');

            app.initDatepicker(); //Date picker
            app.initIcheck(); // iCheck
            app.initFloatLabels(); //float-labels-js
            app.initSelect2(); // Select2
            app.initBootstrapSlider();
            app.initDoctorForm();
            app.initTimePicker();
            app.initDatetimePicker();
            app.initPreventStringCharacters();
            if (!isAutoFillCityDisabled) {
                app.initAutocompleteCityFromZip(true, false, true);
            }
            app.initFieldsDatesOnClickIcon();
        };

        /**
         * Close modal
         */
        app.closeModal = function () {
            $('#admin-modal').modal('hide');
        };

        /**
         * Reload window even if we are in modal
         */
        app.reloadWindow = function () {
            window.parent.location.reload();
        };

        /**
         * Reload block page with Ajax
         *
         * @param {int} id
         * @param {boolean} removeClassIn
         */
        app.reloadBlockAjax = function (id, removeClassIn) {
            var type = null;
            var sousType = null;
            if (Array.isArray(id)) {
                type = id[1];
                sousType = id[2];
                id = id[0];
            }

            var bloc = $('#' + (id === 'reload-customer-dashboard' ? 'customer-dashboard' : id));
            var selector = null;
            if (id == 'box-customer-prices') {
                selector = '#' + id + ' .bloc-tarifs .nav-tarifs';
            } else if (id == 'box-doctor-global-pref') {
                selector = '#' + id + ' .bloc-preferences .nav-preferences';
            } else {
                selector = '#' + (id === 'reload-customer-dashboard' ? 'customer-dashboard' : id) + ' .box-mp .box-header';
            }

            if (selector != null) {
                $(selector).before('<div class="overlay"><i class="fa fa-refresh fa-spin"></i></div>');
            }

            var url = bloc.attr('data-url-reload-ajax');
            var event = bloc.attr('data-event');
            $.ajax({
                url: url,
                method: 'GET',
                success: function (data, textStatus, xhr) {
                    bloc.html(data);

                    if (removeClassIn && id != 'box-customer-group' && id != 'box-doctor-fee' && id != 'box-doctor-suivi') {
                        bloc.find('.panel-collapse').removeClass('in');
                    }

                    // Activate good tab
                    if (type != null && sousType != null) {
                        app.activateTabPrice(type, sousType);
                    }

                    // Launch event
                    if (event != undefined && event != null && event != '') {
                        $(document).trigger(event);
                    }

                    app.initConfirm(); // Init Confirm
                    app.initAjaxForm(); // Click sur a.modal-ajax-form
                    app.processAfterAjaxResponse(data);
                    if (id === 'reload-customer-dashboard') {
                        $('.customer-info-tab').click();
                    }
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        /**
         * Redirect to specific url
         *
         * @param {array} tab
         */
        app.redirectToUrl = function (tab) {
            window.parent.location = tab[0];
        };

        /**
         * Show Accordion block by adding .in class
         *
         * @param {string} idBloc
         */
        app.showBlocAccordion = function (idBloc) {
            $('#' + idBloc).addClass('in');
        };

        /**
         * Submit form in modal
         *
         * @param elt
         */
        app.submitModalForm = function (elt) {
            var url = elt.attr('action');
            var formData = new FormData(elt[0]);
            var pathSpinnerImg = $('#admin-modal').attr('data-path-spinner');
            $('#admin-modal .modal-body').html('<img src="' + pathSpinnerImg + '" width="50px" height="50px" />');

            var fileUploadParams = {};
            for (var i = 0; i < elt[0].length; i++) {
                var elementToTest = elt[0][i];
                if (elementToTest.hasAttribute('type') && elementToTest.getAttribute('type') === 'file') {
                    fileUploadParams = {
                        data: formData,
                        processData: false,
                        cache: false,
                        contentType: false,
                    };
                    break;
                }
            }
            $.ajax(Object.assign(
                {
                    url: url,
                    method: 'POST',
                    data: elt.serialize(),
                    success:
                        function (data, textStatus, xhr) {
                            app.processAfterAjaxResponse(data, $('#admin-modal .modal-body'));
                            initAjaxForm();
                            return false;
                        },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                },
                fileUploadParams
            ));
        };

        /**
         * String to display in Select2 AJAX
         *
         * @param {object} obj
         * @returns {string}
         */
        function select2FormatDisplay(obj) {
            if (obj.fullname) {
                return obj.fullname;
            } else if (obj.text) {
                return obj.text;
            }

            return obj.id;
        }

        /**
         * Init Ajax Form
         */
        function initAjaxForm(confirmationMessage, thirdArgCityAjax) {
            $('#admin-modal form').addClass('form-ajax');

            $('#admin-modal .custom-select').select2({
                language: "fr",
                theme: "classic",
                width: '100%'
            });

            $('#admin-modal .custom-select-ajax').each(function () {
                $(this).select2({
                    language: "fr",
                    width: '100%'
                });
            }).each(function () {
                if ($(this).hasClass('required')) {
                    $(this).next('.select2-container').find('.select2-selection').addClass('select2-selection__required');
                }
            });

            $('#admin-modal .custom-select-ajax-perso').each(function () {

                $(this).children('option').each(function () {
                    $(this).attr('selected', 'selected');
                });

                const url = $(this).attr('data-route-ajax');

                $(this).on('select2:unselect', function (e) {
                    $(this).find("option[value='" + e.params.data.id + "']").remove();
                    $(this).trigger('change.select2');
                });

                $(this).select2({
                    language: "fr",
                    width: '100%',
                    ajax: {
                        url: url,
                        dataType: 'json',
                        data: function (params) {
                            return {
                                q: params.term, // search term
                                page: params.page
                            };
                        },
                        processResults: function (data) {
                            // Tranforms the top-level key of the response object from 'items' to 'results'
                            return {
                                results: data
                            };
                        },
                        cache: true
                    },
                    templateResult: select2FormatDisplay,
                    templateSelection: select2FormatDisplay
                });
            });

            $('#admin-modal .admin-modal_submit')
                .off('click')
                .on('click', function (e) {
                        e.preventDefault();

                        app.changeValueHiddenField($(this));

                        $('#admin-modal form.form-ajax').submit();
                    }
                );

            $('#admin-modal form.form-ajax')
                .off('submit')
                .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        var cboxActive = $('.cbox-price-type');
                        if (cboxActive != undefined && cboxActive != null && cboxActive.length > 0 && cboxActive.is(':checked') && confirmationMessage != '' && confirmationMessage != null) {
                            if (confirm(confirmationMessage)) {
                                app.submitModalForm($(this));
                            }
                        } else {
                            app.submitModalForm($(this));
                        }
                    }
                );
            app.initTimePicker();
            app.initDatetimePicker();
            app.initAjaxForm();
            app.initKnob();
            app.initAutocompleteCityFromZip(true, true, thirdArgCityAjax);
            $('input.zip').trigger('keyup');
        }

        /**
         * Remove element from DOM
         * @param {string} id
         */
        app.removeElement = function (id) {
            $('#' + id).remove();
        };

        /**
         * Submit Ajax Form not in modal
         * @param {string} containerSpinner
         * @param {string} selectorContainerHtml
         */
        app.submitAjaxForm = function (containerSpinner, selectorContainerHtml) {
            $('form.form-ajax')
                .off('submit')
                .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        app.actionSubmitAjaxForm($(this), containerSpinner, selectorContainerHtml);
                    }
                );
        };

        /**
         * Display spinner gif
         * @param {jQuery} elt
         * @param {string} containerSpinner
         */
        app.displaySpinner = function (elt, containerSpinner) {
            var pathSpinnerImg = elt.attr('data_path_spinner');
            $(containerSpinner).html('<div class="mp-spinner text-center"><img src="' + pathSpinnerImg + '" width="50px" height="50px" /></div>');
        };

        /**
         * Function called in submitAjaxForm() to Submit Ajax Form not in modal
         * @param {jQuery} elt
         * @param {string} containerSpinner
         * @param {string} selectorContainerHtml
         */
        app.actionSubmitAjaxForm = function (elt, containerSpinner, selectorContainerHtml) {
            var url = elt.attr('action');
            var formData = new FormData(elt[0]);

            // Display spinner
            app.displaySpinner(elt, containerSpinner);

            var fileUploadParams = {};
            for (var i = 0; i < elt[0].length; i++) {
                var elementToTest = elt[0][i];
                if (elementToTest.hasAttribute('type') && elementToTest.getAttribute('type') === 'file') {
                    fileUploadParams = {
                        data: formData,
                        processData: false,
                        cache: false,
                        contentType: false
                    };
                    break;
                }
            }

            $.ajax(Object.assign(
                {
                    url: url,
                    method: 'POST',
                    data: elt.serialize(),
                    success:
                        function (data, textStatus, xhr) {
                            app.processAfterAjaxResponse(data, $(selectorContainerHtml));
                            return false;
                        },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                },
                fileUploadParams
            ));
        };

        /**
         * Display error after form ajax submit
         * @param {array} args
         */
        app.displayErrorFormAjax = function (args) {
            if (Array.isArray(args) && args.length > 0) {
                var cssClass = args[0];
                var message = args[1] === '' ? 'Une erreur est survenue' : args[1];

                $('.mp-spinner').remove();
                $('#alert-error-search-bill').remove();
                $('.' + cssClass).prepend('<div id="alert-error-search-bill" class="alert alert-danger text-center error-ajax-form-not-modal">' + message + '</div>');
            }
        };

        /** ================================== End General ======================================= **/


        /** ================================================================================== **/
        /** ================================== PLUGINS ======================================= **/
        /** ================================================================================== **/

        /**
         * Get config DataTables
         * @returns {object}
         */
        app.getConfigDatatable = function () {
            return {
                "sProcessing": "Traitement en cours...",
                "sSearch": "Rechercher&nbsp;:",
                "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
                "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "sInfoPostFix": "",
                "sLoadingRecords": "Chargement en cours...",
                "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
                "oPaginate": {
                    "sFirst": "Premier",
                    "sPrevious": "Pr&eacute;c&eacute;dent",
                    "sNext": "Suivant",
                    "sLast": "Dernier"
                },
                "oAria": {
                    "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                    "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                },
                "fnDrawCallback": function () {
                    app.initAjaxForm();
                    app.initConfirm();
                },
                "fnInfoCallback": function () {
                    app.initAjaxForm();
                    app.initConfirm();
                },
                "aoColumnDefs": [{
                    "bSortable": false,
                    "aTargets": ['sorting-disabled']
                }]
            };
        };

        /**
         * Init one DataTable
         * @param that
         * @param cptDatatable
         * @param dataTableLangageParams
         * @param scrollX
         */
        app.initOneDatatable = function (that, cptDatatable, dataTableLangageParams, scrollX) {
            if (cptDatatable === null) {
                cptDatatable = allDatatable.length;
            }

            if (dataTableLangageParams === null) {
                dataTableLangageParams = app.getConfigDatatable();
            }

            var bLengthChange = that.attr('data-length-change') ? (that.attr('data-length-change') == "false" ? false : true) : true;
            var bPaginate = that.attr('data-paginate') ? (that.attr('data-paginate') == "false" ? false : true) : true;
            var ordering = that.attr('data-paginate') ? (that.attr('data-ordering') == "false" ? false : true) : true;
            var searching = that.attr('data-paginate') ? (that.attr('data-searching') == "false" ? false : true) : true;
            var defaultOrder = that.attr('data-default-order') == 'false' ? [] : null;

            var params = {
                "oLanguage": dataTableLangageParams,
                "bLengthChange": bLengthChange,
                "bPaginate": bPaginate,
                "responsive": {
                    "details": {
                        "target": -1
                    }
                },
                "ordering": ordering,
                "searching": searching,
                "scrollX": scrollX
            };

            if (defaultOrder != null) {
                params.order = defaultOrder;
            }

            if (that.attr('data-ajax')) {
                params.ajax = that.attr('data-ajax');
                params.initComplete = function () {
                    app.initAjaxForm();
                    app.initConfirm();
                };
            }

            var uniqid = cptDatatable;
            that.attr('id-datatable', uniqid);
            allDatatable[cptDatatable] = that.DataTable(params);

            // Obligé de le faire ici car création de la div .dataTables_wrapper lors de l'inistialisation de DataTables
            $('#accordion-medical-control-third-right .dataTables_wrapper').css('margin-top', '50px');
        };

        /**
         * Init all Datatables of a page
         */
        app.initDataTables = function () {
            var dataTableLangageParams = app.getConfigDatatable();

            var cptDatatable = 0;
            $('.datatable').each(function () {
                cptDatatable++;

                app.initOneDatatable($(this), cptDatatable, dataTableLangageParams, false);
            });
        };

        /**
         * Init iCheck
         */
        app.initIcheck = function () {
            $('.icheck:not(.done)').iCheck({
                cursor: true,
                labelHover: true,
                labelHoverClass: 'label-icheck',
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
                increaseArea: '20%' // optional
            });

            $('.icheck').addClass('done');
        };

        /**
         * Init Datepicker
         */
        app.initDatepicker = function () {
            $('.datepicker:not(.done)').datepicker({
                autoclose: true,
                language: 'fr'
            }).on('changeDate', function (e) {
                e.target.parentNode.classList.add('fl-is-active');
                e.target.focus();

                // Change la date de début pour les autres champs dates
                app.initCheckDates($(this));
            });

            // Change la date de début pour les champs dates à l'arrivée sur le formulaire
            app.initCheckDates();

            $('.datepicker').addClass('done');
        };

        /**
         * Init control of dates
         * @param {jQuery} that
         */
        app.initCheckDates = function (that) {
            if (that === undefined || that === 'undefined' || that === null) {
                $('.check-date').each(function () {
                    var order = parseInt($(this).attr('data-order'));
                    var nextOrder = order + 1;

                    var $nextField = $('input.check-date[data-order=' + nextOrder + ']');

                    // Changement startDate pour qu'on puisse pas sélectionner une date antérieure
                    var newDate = $(this).val();
                    if (newDate !== '') {
                        if (!$nextField.hasClass('datetimepicker')) {
                            $nextField.datepicker('setStartDate', newDate);
                        }
                    }
                });
            } else {
                if (that.hasClass('check-date')) {
                    var currentFieldChange = that;
                    var orderCurrentFieldChange = parseInt(currentFieldChange.attr('data-order'));

                    $('.check-date').each(function () {
                        var order = parseInt($(this).attr('data-order'));
                        if (order > orderCurrentFieldChange) {
                            var newDate = currentFieldChange.val();

                            // Changement startDate pour qu'on puisse pas sélectionner une date antérieure
                            $(this).datepicker('setStartDate', newDate);

                            // Champ vide si la date du champ courant est plus petite que la nouvelle startDate
                            var newDateClassicFormat = app.transformStringFrenchDateToClassicFormat(newDate);
                            var dateClassicFormat = app.transformStringFrenchDateToClassicFormat($(this).val());
                            if (dateClassicFormat < newDateClassicFormat) {
                                $(this).val('');
                            }
                        }
                    });
                }
            }
        };

        /**
         * Transform string french date (dd/mm/YYYY) to string date format Y-m-d
         * @param {string} date
         */
        app.transformStringFrenchDateToClassicFormat = function (date) {
            var splittedDate = date.split('/');
            if (splittedDate.length !== 3) {
                return 0;
            }

            return splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
        };

        /**
         * Init select2
         */
        app.initSelect2 = function () {
            // Override bootstrap enforceFocus method to fix the select2 searchbar being frozen
            $.fn.modal.Constructor.prototype.enforceFocus = function () {
            };
            $.fn.modal.Constructor.prototype._enforceFocus = function () {
            };
            $('.custom-select').select2({
                language: "fr",
                width: '100%'
            });
            $('.custom-select-keep-open').select2({
                language: "fr",
                width: '100%',
                closeOnSelect : false,
            });
        };

        /**
         * Init IntroJS
         */
        app.initIntroJS = function () {
            introJs()
                .setOption('nextLabel', 'Suivant')
                .setOption('prevLabel', 'Précédent')
                .setOption('skipLabel', 'Fermer')
                .setOption('doneLabel', 'Terminé')
                .setOption('showProgress', true)
                .start();
        };

        /** ================================== END PLUGINS ======================================= **/


        /** ================================================================================== **/
        /** ================================== Customer ====================================== **/
        /** ================================================================================== **/

        /**
         * Init the customer list
         */
        app.initCustomerList = function () {
            app.initButtonFilter($('.btn-filter-customer'), $('#datatable-customer'), $('.form-customer'), 'customer');
        };

        /**
         * Init the doctor to mandate / not to mandate
         */
        app.initDoctorToMandateNotToMandate = function () {
            var $selectMandate = $('.select-mandate');
            var $selectNotMandate = $('.select-not-mandate');

            app.handleDoctorMandateSelect($selectMandate, 'mandate');
            app.handleDoctorMandateSelect($selectNotMandate, 'not-mandate');

            $selectMandate.on('select2:select', function (e) {
                app.handleDoctorMandateSelect($selectMandate, 'mandate');
            });

            $selectNotMandate.on('select2:select', function (e) {
                app.handleDoctorMandateSelect($selectNotMandate, 'not-mandate');
            });

            $selectMandate.on('select2:unselect', function (e) {
                $selectNotMandate.append($('<option>', {value: e.params.data.id, text: e.params.data.text}));
            });

            $selectNotMandate.on('select2:unselect', function (e) {
                $selectMandate.append($('<option>', {value: e.params.data.id, text: e.params.data.text}));
            });
        };

        /**
         * Handle the 'on' action of the doctor mandate/not-mandate select
         * @param $selector
         * @param $type
         */
        app.handleDoctorMandateSelect = function ($selector, $type) {
            if ($selector.val()) {
                switch ($type) {
                    case 'mandate' :
                        $selector.val().forEach(function (e) {
                            $(".select-not-mandate option[value='" + e + "']").remove();
                        });
                        break;
                    case 'not-mandate':
                        $selector.val().forEach(function (e) {
                            $(".select-mandate option[value='" + e + "']").remove();
                        });
                        break;
                }
            }
        };

        /**
         * Customer show page
         *
         * @param {string} urlAverageComment
         */
        app.initCustomerShowPage = function (urlAverageComment) {
            // Init Collapse Bootstrap
            app.initCollapse('accordion-customer');

            // IntroJS
            $('.launch-tuto').on('click', function () {
                app.initIntroJS();
            });

            // Reload each block
            $('.ajax-reload').each(function () {
                app.reloadBlockAjax($(this).attr('id'), true);
            });

            // Listeners
            $(document)
                .on('orgchart', function (e) {
                    // Construction du bloc "Arboresence groupe"
                    app.constructArboresenceGroup();

                    // Suppression du groupe
                    $('.group-important-action.remove-group').on('click', function (event) {
                        event.preventDefault();
                        app.removeGroupCustomer($(this));
                    });
                })
                .on('orgchart-partnership', function (e) {
                    // Construction du bloc Partnership
                    app.constructArboresenceGroup('#bloc-arboresence-partnership');
                })
                .on('calculateAverageComments', function (e) {
                    // Calcul de la moyenne des commentaires
                    app.initCommentAverage(urlAverageComment);
                })
                .on('initDatatableContacts', function (e) {
                    app.initOneDatatable($('#datatable-contact'), null, null, false);
                })
                .on('initGed', function (e) {
                    app.initOneDatatable($('#datatable-ged'), null, null, false);
                })
            ;

            app.collapseTarifs();
            app.initBlocNote();
        };

        /**
         * Suppression d'un groupe
         * @param {jQuery} elementClicked
         */
        app.removeGroupCustomer = function (elementClicked) {
            var url = elementClicked.attr('href');
            $.ajax({
                url: url,
                method: 'GET',
                success: function (json, textStatus, xhr) {
                    app.processAfterAjaxResponse(json);
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        app.initBlocNote = function () {
            const $textAreaNote = $('.text-area-note');
            $('.btn-save-note').on('click', function (e) {
                const $loader = $('.loader');
                $loader.removeClass('hidden');
                e.preventDefault();
                $.ajax({
                    url: $textAreaNote.attr('data-url'),
                    data: {
                        newNote: $textAreaNote.val()
                    },
                    method: 'GET',
                    success: function (json, textStatus, xhr) {
                        app.processAfterAjaxResponse(json);
                        $loader.addClass('hidden');
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                });
            })
        };

        /**
         *  Show collapse tarifs on click
         */
        app.collapseTarifs = function () {
            $(document).on('click', '.nav-tarifs__item__link:not(".no-click")', function () {
                $('#accordion-bloc-prices').collapse('show');
            });
            $(document).on('hide.bs.collapse', '#accordion-bloc-prices', function () {
                $('.bloc-tarifs').addClass('child-collapse');
            });
            $(document).on('show.bs.collapse', '#accordion-bloc-prices', function () {
                $('.bloc-tarifs').removeClass('child-collapse');
            })
        };

        /**
         * Group - Init orgchart
         *  Si 3 argurments, la méthode construit pour le orgchart Partenariat
         * @param {Array} tabArguments
         * @returns {*}
         */
        app.initOrgChart = function (tabArguments) {
            if (tabArguments[1] == '1') {

                var nodeTemplateGroup = function (data) {
                    return '<div class="title ' + data.classCss + '" title="' + data.name + '">' + data.name + '</div>' +
                        '<div class="invoices">' + data.invoices + '</div>' +
                        '<a href="' + data.url_update_invoices + '" class="link-maj-infos-invoices hide" target="_blank"></a>';
                };

                let nodeTemplatePartnership = function (data) {
                    return '<div class="title ' + data.classCss + '">' + data.name + '</div>' +
                        '<div class="invoices"></br></div>';
                };


                if (tabArguments.length === 3) {
                    var orgChart = $('#org-partenariat').orgchart({
                        data: tabArguments[0],
                        nodeTemplate: nodeTemplatePartnership,
                        parentNodeSymbol: false
                    });
                } else {
                    var orgChart = $('#org-group').orgchart({
                        data: tabArguments[0],
                        nodeTemplate: nodeTemplateGroup,
                        parentNodeSymbol: false
                    });
                }


                return orgChart;
            }

            return null;
        };

        /**
         * Group - Construct orgchart
         */
        app.constructArboresenceGroup = function (blocId) {
            var url = blocId ? $(blocId).attr('data-url') : $('#bloc-arboresence').attr('data-url');
            $.ajax({
                url: url,
                method: 'GET',
                success: function (json, textStatus, xhr) {
                    app.processAfterAjaxResponse(json);
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        /**
         * Group - Build view customer group
         *
         * @param {Array} tabArguments
         * @param blocId
         */
        app.buildViewArboresenceGroup = function (tabArguments) {
            if (tabArguments.length === 1) {
                $('#bloc-arboresence .box-body').html(tabArguments[0]);
            } else if (tabArguments.length > 1) {
                $(tabArguments[1] + ' .box-body').html(tabArguments[0]);
            }
        };

        /**
         * Group - Listener on click on orgchart node
         */
        app.clickNodeOrgChart = function () {
            $(document).off('click', '#org-group .node');
            $(document).on('click', '#org-group .node', function () {
                var url = $(this).find('a.link-maj-infos-invoices').attr('href');

                var footer = '<button type="button" class="btn btn-default" data-dismiss="modal" >Retour</button>';
                footer += '<button type="button" class="btn btn-success admin-modal_submit">Valider</button>';
                $.get(url, function (data) {
                    app.createModal('Modification des informations de facturation', data, true, footer);
                    initAjaxForm(null, true);
                });
            });
        };

        /**
         * Facturation - Process financial health after update form update facturation
         * @param {array} newData
         */
        app.replaceFinancialHealth = function (newData) {
            var $alert = $('.alert-customer-financial-health');

            if ($alert.length > 0) {
                if (newData[0] != '' && newData[1] != '') {
                    $alert.removeClass();
                    $alert.addClass('alert alert-info text-center alert-customer-financial-health ' + newData[0]);
                    $alert.find('.financial-health').html(newData[1]);
                    $alert.show();
                } else {
                    $alert.hide();
                }
            }
        };

        /**
         * Comments - Calculate average of customer comments
         */
        app.initCommentAverage = function () {
            $.ajax({
                url: $('#satisfaction-average').attr('data-reload-url'),
                method: 'GET',
                success: function (html) {
                    $('#satisfaction-average').html(html);
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        /**
         * Comments - Calculate average of customer comments
         */
        app.initDatatableContacts = function () {
            app.initDataTables(); // TODO : plus tard, initialiser que la datatable des contacts
        };

        /**
         * Init Price Vaccination Form
         */
        app.initPriceVaccinationForm = function () {
            const $selectPriceVaccinationType = $('.select-price-vaccination-type');
            $selectPriceVaccinationType.on('change', function () {
                const $fieldFraisCarenceNonPresentation = $('.price-vaccination-frais-carence-non-presentation');
                const $fieldFraisCarence = $('.price-vaccination-frais-carence');
                const $fieldAmount = $('.price-vaccination-amount');
                const $fieldFraisKmSupp = $('.price-vaccination-frais-km-supp');
                const $fieldPriceVaccinationForfaitKm = $('.price-vaccination-forfait-km');

                $.ajax({
                    url: $('.select-price-vaccination-type option:selected').attr('data-url'),
                    method: 'GET',
                    success: function (json, textStatus, xhr) {
                        $fieldFraisCarenceNonPresentation.val(json.fraisCarenceNoPresentation);
                        $fieldFraisCarence.val(json.fraisCarence);
                        $fieldAmount.val(json.amount);
                        $fieldFraisKmSupp.val(json.fraisKmSupp);
                        $fieldPriceVaccinationForfaitKm.val(json.forfaitKm);
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Une erreur est survenue');
                    }
                });

            })
        };

        /**
         * Control Provided Vaccination
         */
        app.controlProvidedVaccination = function ($cboxProvidedVaccins) {
            var $vaccinationAmount = $('.vaccination-amount');
            var $vaccinationProvidedAmount = $('.vaccination-provided-amount');

            if (!$cboxProvidedVaccins.is(':checked')) {
                $vaccinationProvidedAmount.attr('disabled', 'disabled');
                $vaccinationProvidedAmount.addClass('input-disabled');
                $vaccinationProvidedAmount.val('');
                $vaccinationAmount.removeAttr('disabled');
                $vaccinationAmount.removeClass('input-disabled');
            } else {
                $cboxProvidedVaccins.on('ifChecked', function () {
                    $vaccinationProvidedAmount.removeAttr('disabled');
                    $vaccinationProvidedAmount.removeClass('input-disabled');
                    $vaccinationAmount.attr('disabled', 'disabled');
                    $vaccinationAmount.addClass('input-disabled');
                    $vaccinationAmount.val('');
                });
            }
        };

        /**
         * Init Price Medical Control Pack Form
         */
        app.initPricePackForm = function () {
            app.calculatePricePack();
            app.calculateEndDateAuto();

            let $selectPackNbControl = $('.select-medical-ctrl-pack');
            let $fieldBalance = $('.price-pack-balance');
            let $fieldUnitPrice = $('.price-pack-unit-price');
            let $fieldOtherNbControl = $('.price-pack-other-nb-controls');
            let $fieldTotalPack = $('.price-pack-total-pack');
            let $fieldDurationPack = $('.price-pack-duration');
            let $fieldFraisCarencePack = $('.price-pack-frais-carence');
            let $fieldFraisCarencenonPresentationPack = $('.price-pack-frais-carence-non-presentation');

            // Solde
            $selectPackNbControl.on('change', function () {
                if ($(this).val() !== undefined && $(this).val() !== null && $(this).val() !== '') {
                    $.ajax({
                        url: $(this).find(":selected").attr('ajax-url'),
                        method: 'GET',
                        success: function (json, textStatus, xhr) {
                            $fieldBalance.val(json.nbControl);
                            $fieldUnitPrice.val(json.unitPrice);
                            $fieldTotalPack.val(json.totalPack);
                            $fieldDurationPack.val(json.durationPack);
                            $fieldFraisCarencePack.val(json.fraisCarencePack);
                            $fieldFraisCarencenonPresentationPack.val(json.fraisCarenceNonPresentationPack);
                            $fieldOtherNbControl.prop('disabled', true);
                        },
                        error: function (xhr, textStatus, codeError) {
                            alert('Une erreur est survenue');
                        }
                    });
                } else {
                    $fieldOtherNbControl.prop('disabled', false);
                }
            });

            $fieldOtherNbControl.on('keyup mouseup', function () {
                if ($(this).val() == '') {
                    $fieldBalance.val($('.price-pack-nb-controls').val());
                    $selectPackNbControl.prop('disabled', false);
                } else {
                    $fieldBalance.val($(this).val());
                    $selectPackNbControl.prop('disabled', true);
                }
            });

            if ($fieldBalance.length) {
                if ($selectPackNbControl.attr('real-nb-control') !== $('.price-pack-balance').val()) {
                    $selectPackNbControl.attr("style", "pointer-events: none;");
                    $fieldOtherNbControl.prop('readonly', 'readonly');
                    $fieldUnitPrice.prop('readonly', 'readonly');
                    $selectPackNbControl.unbind();
                    $fieldOtherNbControl.unbind();
                    $fieldUnitPrice.unbind();
                }
            }
        };

        /**
         * Price - Calculate auto total pack field in modal
         */
        app.calculateEndDateAuto = function () {
            // Calcul du champ Date de fin de validité
            $('.price-pack-date-bdc input').on('keyup change', function (e) {
                var dateBdc = $(this).val();
                var duration = $('.price-pack-duration input').val();

                app.guessEndDateAuto(dateBdc, duration);
            });

            $('.price-pack-duration input').on('keyup', function (e) {
                var duration = $(this).val();
                var dateBdc = $('.price-pack-date-bdc input').val();

                app.guessEndDateAuto(dateBdc, duration);
            });
        };

        /**
         * Calculate just the new end date
         *
         * @param {string} dateBdc
         * @param {int|string} duration
         */
        app.guessEndDateAuto = function (dateBdc, duration) {
            var splitDate = dateBdc.split('/');
            var date = moment(splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]);
            if (date !== '') {
                date.add(duration, 'months');
                $('.price-pack-end-date input').val(date.format('D/M/YYYY'));
            }
        };

        /**
         * Price - Calculate auto total pack field in modal
         */
        app.calculatePricePack = function () {
            // Calcul du champ total pack
            $('.price-pack-nb-controls, .price-pack-other-nb-controls').on('keyup change', function (e) {
                var value = $(this).val();
                var pu = $('.price-pack-unit-price').val().replace(',', '.');

                $('.price-pack-total-pack').val((value * pu).toFixed(2));
            });
            $('.price-pack-unit-price').on('keyup', function (e) {
                var pu = $(this).val().replace(',', '.');
                var value = 0;
                if ($('.price-pack-other-nb-controls').length) {
                    if ($('.price-pack-other-nb-controls').val() != '') {
                        value = $('.price-pack-other-nb-controls').val();
                    }
                } else if ($('.price-pack-nb-controls') != '') {
                    value = $('.price-pack-nb-controls').val();
                }

                $('.price-pack-total-pack').val((value * pu).toFixed(2));
            });
        };

        /**
         * Price - Activate good tab of prices block
         *
         * @param type
         * @param sousType
         */
        app.activateTabPrice = function (type, sousType) {
            // Disable default activated
            $('a[href="#controle_medical"]').closest('li').removeClass('active');
            $('#controle_medical').removeClass('active');

            // Activation good tab
            var id = '#' + type;
            var link = $('a[href="' + id + '"]');
            var tab = link.closest('li');
            tab.addClass('active');
            $(id).addClass('active');

            // Activation sub menu if exist
            if (sousType != null && sousType != '') {
                // Disable default sub menu activated
                $('a[href="#full-price"]').closest('li').removeClass('active');
                $('#full-price').removeClass('active');

                id = '#' + sousType;
                link = $('a[href="' + id + '"]');
                tab = link.closest('li');
                tab.addClass('active');
                $(id).addClass('active');
            }
        };

        /**
         * Init Form Customer Billing
         * @param {string} sendAttentionTo
         * @param {string} financialHealthRj
         */
        app.initFormCustomerBilling = function (sendAttentionTo, financialHealthRj) {
            // Toggle Contacts
            var $cboxContacts = $('.cbox-interlocuteur-comptable');
            var $containerContacts = $('.container-customer-billing-contacts');

            app.toggleCustomerBillingContacts($cboxContacts, $containerContacts);
            $cboxContacts.on('ifChanged', function () {
                app.toggleCustomerBillingContacts($(this), $containerContacts);
            });

            // Toggle Bon de commande
            var $cboxOrderForm = $('.cbox-order-form');
            var $containerOrderForm = $('.container-customer-billing-order-form');

            app.toggleCustomerBillingOrderForm($cboxOrderForm, $containerOrderForm);
            $cboxOrderForm.on('ifChanged', function () {
                app.toggleCustomerBillingOrderForm($(this), $containerOrderForm);
            });

            // Toggle Santé financière
            var $selectFinancialHealth = $('.select-financial-health');
            var $containerFinancialHealth = $('.container-customer-financial-health');

            $selectFinancialHealth.on('change', function () {
                var $optionsSelected = $(this).find(':selected');
                if ($optionsSelected.hasClass(financialHealthRj)) {
                    $containerFinancialHealth.slideDown();
                } else {
                    $containerFinancialHealth.slideUp();
                    $containerFinancialHealth.find('input').each(function () {
                        $(this).val('');
                    });
                }
            });
            $selectFinancialHealth.change();
        };

        /**
         * Toggle display contacts in customer billing form
         * @param {jQuery} $cbox
         * @param {jQuery} $container
         */
        app.toggleCustomerBillingContacts = function ($cbox, $container) {
            if ($cbox.is(':checked')) {
                $container.slideDown();
            } else {
                $container.slideUp();
                $container.find('.icheck').each(function () {
                    $(this).iCheck('uncheck');
                });
            }
        };

        /**
         * Toggle display order form block in customer billing form
         * @param {jQuery} $cbox
         * @param {jQuery} $container
         */
        app.toggleCustomerBillingOrderForm = function ($cbox, $container) {
            if ($cbox.is(':checked')) {
                $container.slideDown();
            } else {
                $container.slideUp();
                $container.find('.icheck').each(function () {
                    $(this).iCheck('uncheck');
                });
            }
        };

        /**
         * Build a chart using chartJS
         * @param {string} canvasId the canvas id which will be replaced by a donut
         * @param {string} dataContainerClass, the div class which has the data-url attributes
         * @param {string} itemDescription describe what each donut area refers to
         * @param {string} chartType doghut/chart etc ... see the chartjs doc for other types
         * @param {boolean} isLegendShown enable/disable legend
         * @param {Array} colors colors of background and borders
         */
        app.chartBuilder = function (canvasId, dataContainerClass, itemDescription, chartType, isLegendShown, colors, showNumber, dateStart, dateEnd) {
            var ctx = document.getElementById(canvasId);
            var ajaxData = [];
            var legend = {
                display: isLegendShown,
                position: "right",
                fullWidth: false,
            };
            if (!colors) {
                colors = {
                    background: [
                        'rgba(115, 192, 107, 0.9)',
                        'rgba(255, 159, 64, 0.9)',
                        'rgba(255, 99, 132, 0.9)',
                        'rgba(160, 120, 180, 0.9)',
                        'rgba(125, 40, 80, 0.9)',
                        'rgba(150, 90, 20, 0.9)',
                        'rgba(200, 80, 50, 0.9)',
                        'rgba(20, 200, 80, 0.9)',
                        'rgba(90, 120, 200, 0.9)',
                        'rgba(114,200,146,0.9)',
                        'rgba(200,110,163,0.9)',
                        'rgba(147,178,200,0.9)'
                    ],
                    border: [
                        'rgba(115, 192, 107, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(160, 120, 180, 1)',
                        'rgba(125, 40, 80, 1)',
                        'rgba(150, 90, 20, 1)',
                        'rgba(200, 80, 50, 1)',
                        'rgba(20, 200, 80, 1)',
                        'rgba(90, 120, 200, 1)',
                        'rgba(114,200,146,0.9)',
                        'rgba(200,110,163,0.9)',
                        'rgba(147,178,200,0.9)'
                    ]
                };
            }
            if (showNumber) {
                legend = {
                    display: isLegendShown,
                    position: "right",
                    fullWidth: false,
                    labels: {
                        generateLabels: function (chart) {
                            var data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return data.labels.map(function (label, i) {
                                    var meta = chart.getDatasetMeta(0);
                                    var ds = data.datasets[0];
                                    var arc = meta.data[i];
                                    var custom = arc && arc.custom || {};
                                    var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                    var arcOpts = chart.options.elements.arc;
                                    var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                    var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                    var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                                    // We get the value of the current label
                                    var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                    return {
                                        // Instead of `text: label,`
                                        // We add the value to the string
                                        text: label + " : " + value,
                                        fillStyle: fill,
                                        strokeStyle: stroke,
                                        lineWidth: bw,
                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        index: i
                                    };
                                });
                            } else {
                                return [];
                            }
                        }
                    }
                }
            }
            var loadDonut = function () {
                $('#' + canvasId).data('chart', new Chart(ctx, {
                    type: chartType,
                    data: {
                        labels: ajaxData.labels,
                        datasets: [{
                            label: itemDescription,
                            data: ajaxData.items,
                            backgroundColor: colors.background,
                            borderColor: colors.border
                        }]
                    },
                    options: {
                        responsive: false,
                        legend: legend,
                        plugins: {
                            labels: {
                                render: function (args) {
                                    return args.percentage < 5 ? '' : args.percentage + ' %'
                                },
                                fontColor: '#000',
                                fontStyle: 'bold',
                                precision: 2,
                                position: 'border',
                                fontFamily: '"Comfortaa", Monaco, monospace'
                            }
                        }
                    }
                }));
            };
            var params = {
                url: $(dataContainerClass).attr('data-url'),
                success: function (data) {
                    ajaxData = data.data;
                    loadDonut();
                    var $parentTitle = $(dataContainerClass).closest('.graph-item');
                    $parentTitle.find('.graph-item__title').removeClass('hidden');

                    const $infoBoxNumber = $('.info-box-number');
                    const nbControl = $('.info-box').attr('data-nb-controls');
                    $infoBoxNumber.each(function (index, value) {
                        if ($(value).attr('data-name') === 'Justifiés') {
                            $(value).closest('info-box-number').html(ajaxData.items[ajaxData.labels.indexOf('Justifiés')] + '(' + ((ajaxData.items[ajaxData.labels.indexOf('Justifiés')] / nbControl) * 100).toFixed(2) + ' %)')
                        }
                        if ($(value).attr('data-name') === 'Suspensifs du complément de salaire') {
                            $(value).closest('info-box-number').html(ajaxData.items[ajaxData.labels.indexOf('Suspensifs du complément de salaire')] + '(' + ((ajaxData.items[ajaxData.labels.indexOf('Suspensifs du complément de salaire')] / nbControl) * 100).toFixed(2) + ' %)')
                        }
                    });
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                },
            };

            if (!dateStart || !dateEnd) {
                params.method = 'GET';
            } else {
                params.method = 'POST';
                params.data = {
                    dateStart: dateStart,
                    dateEnd: dateEnd
                };
            }

            $.ajax(params);
        };

        /**
         *
         * @param $chart
         * @param dataContainerClass
         * @param dateStart
         * @param dateEnd
         */
        app.chartUpdater = function ($chart, dataContainerClass, dateStart, dateEnd) {
            var chart = $chart.data('chart');
            var params = {
                url: $(dataContainerClass).attr('data-url'),
                success: function (data) {
                    chart.data.labels = data.data.labels
                    chart.data.datasets[0].data = data.data.items;
                    chart.update();
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                },
                method: 'POST',
                data: {
                    dateStart: dateStart,
                    dateEnd: dateEnd
                }
            };
            $.ajax(params);
        };

        /** ================================== End Customer ======================================= **/


        /** ================================================================================== **/
        /** ================================== Doctor ======================================= **/
        /** ================================================================================== **/

        /**
         * Initialize the doctor list
         */
        app.initDoctorList = function () {
            app.initButtonFilter($('.btn-filter-doctor'), $('#datatable-doctor'), $('.form-doctor'), 'doctor');
        };

        /**
         * Init doctor show page
         */
        app.initDoctor = function () {
            // Init Collapse
            app.initCollapse('accordion-honoraires-reporting');
            app.initCollapse('accordion-suivi-ged');

            // Reload each block
            $('.ajax-reload').each(function () {
                app.reloadBlockAjax($(this).attr('id'), true);
            });

            // IntroJS
            $('.launch-tuto').on('click', function () {
                app.initIntroJS();
            });

            // Listeners
            $(document)
                .on('initGed', function (e) {
                    app.initOneDatatable($('#datatable-ged'), null, null, false);
                })
                .on('initCalendar', function (e) {
                    app.initCalendar();
                })
                .on('initSuivi', function (e) {
                    // Calcul de la moyenne des commentaires
                    app.initCommentAverage();
                })
                .on('initInfoGeneral', function (e) {
                    app.initBlocNote();
                });
        };

        /**
         * Init all the doctor form in modal to hide element etc ...
         */
        app.initDoctorForm = function (urlGenerateIncrementalNumber) {
            app.initHideSomething('.vaccination', '.doctor-vaccination-forfeit', 'Checkbox', null, false);
            app.initHideSomething('.fee-payment-rib', '.doctor-rib', 'Checkbox', null, false);
            app.initHideSomething('.doctor-state-select', '.field-edit-other-state', 'Select', 'doctor-state-other', false);
            app.initHideSomething('.requirement_type_complement', '.field-preferences-expertise-complement', 'Checkbox', null, false);

            // Génération du numéro incrémental
            if (urlGenerateIncrementalNumber != undefined && urlGenerateIncrementalNumber != '' && urlGenerateIncrementalNumber != null) {
                app.generateNumIncrementalDoctor(urlGenerateIncrementalNumber);
                $(document.body)
                    .off('blur', '.bloc-address-principale .zip')
                    .on('blur', '.bloc-address-principale .zip', function () {
                        app.generateNumIncrementalDoctor(urlGenerateIncrementalNumber);
                    })
                ;
            }
        };

        /**
         * Doctor - Generate new incremental number for doctor
         * @param {string} url
         */
        app.generateNumIncrementalDoctor = function (url) {
            var zip = $('.bloc-address-principale .zip').val();
            if (zip !== '') {
                if (zip.length < 5) {
                    alert('Veuillez saisir un code postal valide pour pouvoir générer le numéro incrémental du médecin');
                } else {
                    var dep = zip.substr(0, 2);

                    $.ajax({
                        url: url,
                        method: 'POST',
                        data: {
                            department: dep
                        },
                        success: function (data) {
                            $('.field-incremental-number').val(data.incremental_number);
                        },
                        error: function (xhr, textStatus, codeError) {
                            alert('Le numéro incrémental n\'a pas pu être généré. Veuillez saisir le code postal de l\'adresse du cabinet.');
                        }
                    });
                }
            }
        };

        /**
         * Doctor - Show or hide a specific div on click in a select
         * @param {HTMLElement} obj
         * @param {string} choice
         * @param {string} div
         * @param {boolean} reverseLogic
         */
        app.handleSelectHideChoice = function (obj, choice, div, reverseLogic) {
            let $divDom = $(div);
            if (Array.isArray(choice)) {
                if (choice.indexOf(obj.find('option:selected').attr('class')) !== -1) {
                    reverseLogic ? $divDom.slideUp() : $divDom.slideDown();
                } else {
                    reverseLogic ? $divDom.slideDown() : $divDom.slideUp();
                }
            } else {
                if (obj.find('option:selected').attr('class') === choice) {
                    reverseLogic ? $divDom.slideUp() : $divDom.slideDown();
                } else {
                    reverseLogic ? $divDom.slideDown() : $divDom.slideUp();
                }
            }
        };

        /**
         * Show or hide a specific div on click in a Radio select
         * @param {string} radioGroup class of the div containing the radios
         * @param {string} div class of the div to show/hide
         * @param {string} choice class of the radio input that shows the div
         * @param {boolean} reverseLogic if true do the opposite, remove a div when checking the chosen radio
         */
        app.handleRadioHideChoice = function (radioGroup, div, choice, reverseLogic) {
            let $divDom = $(div);
            if ($(radioGroup).find(choice).is(':checked')) {
                reverseLogic ? $divDom.slideUp() : $divDom.slideDown();
            } else {
                app.clearDivsInputs($divDom);
                reverseLogic ? $divDom.slideDown() : $divDom.slideUp();
            }
        };

        /**
         * Doctor - Show or hide a specific div on click in a checbox
         * @param {string} cssClass
         * @param {string} div
         * @param {boolean} reverseLogic
         */
        app.handleCheckedHide = function (cssClass, div, reverseLogic) {
            let $divDom = $(div);
            if ($(cssClass).is(':checked')) {
                reverseLogic ? $divDom.slideUp() : $divDom.slideDown();
            } else {
                app.clearDivsInputs($divDom);
                reverseLogic ? $divDom.slideDown() : $divDom.slideUp();
            }
        };

        /**
         * Doctor - When checking one checkbox from the array every other checkboxes from this array is unchecked
         * @param {Array} array All the checkboxes classes
         */
        app.initOnCheckUnCheckOthers = function (array) {
            array.forEach(function (element) {
                $(element).on('ifChanged', function (event) { // On ajoute un evenement a chaque element de l'array
                    if (!$(element).is(':checked')) { // Si uncheck on return pour eviter appel recurssif
                        return;
                    }
                    var tmp = $.extend(true, [], array); // deep copy de array
                    var index = tmp.indexOf(element);
                    tmp.splice(index, 1); // On remove l'element actuel de la copie de l'array pour pas qu'il se uncheck
                    tmp.forEach(function (toUnCheck) {
                        if ($(toUnCheck).is(':checked')) {
                            $(toUnCheck).iCheck('uncheck');
                        }
                    })
                });
            });
        };

        /**
         * Comments - Calculate average of doctor comments
         */
        app.initCommentDoctorAverage = function () {
            app.initCommentAverage();
        };

        /**
         * Allow to add a specific div when clicking on a select or a checkbox
         * @param {string} cssClass the select or checkbox class
         * @param {string} div the div to hide
         * @param {string} type Checkbox or Select
         * @param {string|array} choice the specific option of the select
         * @param {boolean} reverseLogic if true do the oposite, remove a div when clicking on a select or a checkbox
         */
        app.initHideSomething = function (cssClass, div, type, choice, reverseLogic) {
            switch (type) {
                case 'Checkbox' :
                    $(cssClass).on('ifChanged change', function () {
                        app.handleCheckedHide(cssClass, div, reverseLogic);
                    });
                    app.handleCheckedHide(cssClass, div, reverseLogic);
                    break;
                case 'Select' :
                    $(cssClass).on('change', function () {
                        app.handleSelectHideChoice($(this), choice, div, reverseLogic);
                    });
                    app.handleSelectHideChoice($(cssClass), choice, div, reverseLogic);
                    break;
                case 'Radio' :
                    $(cssClass).find('input').on('change', function () {
                        app.handleRadioHideChoice(cssClass, div, choice, reverseLogic);
                    });
                    app.handleRadioHideChoice(cssClass, div, choice, reverseLogic);
                    break;
            }
        };
        /**
         * Allow to match checked inputs in different input groups
         * @param {string} inputGroupClass class of the inputs to match
         * @param {boolean} reverseLogic if true do the opposite, uncheck targeted input if the base input is checked
         * @param {string} reverseTargetClass class of the input targeted by the reverse logic
         */
        app.matchInputs = function (inputGroupClass, reverseLogic, reverseTargetClass) {
            let inputs = $('input'+inputGroupClass)
            inputs.toArray().forEach(function (input) {
                $(input).on('ifChanged change', function () {
                    if (input.checked === true) {
                        if (reverseLogic) {
                            $(reverseTargetClass).prop('checked', false).trigger('change');
                        } else {
                            $('.'+input.className).prop('checked', true);
                        }
                    }
                })
            })
        }

        /**
         * Clears all the inputs contained within a given div
         * @param {HTMLElement} $obj
         */
        app.clearDivsInputs = function ($obj) {
            $obj.find('input[type=radio]').prop('checked', false).trigger('change');
            $obj.find('input[type=checkbox]').prop('checked', false).trigger('change');
            $obj.find('input[type=text]').val(null);
        }

        /**
         * Init the bootstrap slider
         */
        app.initBootstrapSlider = function () {
            $('.bootstrap-slider-doctor').slider({
                formatter: function (value) {
                    switch (value) {
                        case 1 :
                            return value + ' semaine';
                        case 4 :
                            return '1 mois';
                        case 6 :
                            return '1 mois et demi';
                        case 8 :
                            return '2 mois';
                        case 10 :
                            return '2 mois et demi';
                        case 12 :
                            return '3 mois';
                        default :
                            return value + ' semaines';
                    }
                }
            });
        };

        /**
         * Init chartJs for the doctor
         */
        app.initDoctorChartJs = function () {
            var ctx = document.getElementById("doctor-chart");
            var orderedComments = [];
            $.ajax({
                url: $('.doctor-donut').attr('data-url'),
                method: 'GET',
                success: function (data) {
                    orderedComments = data.data;
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                },
                async: false
            });
            var myChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: ["Satisfait", "Moyen", "Pas de satisfait"],
                    datasets: [{
                        label: '# de commentaire(s)',
                        data: orderedComments,
                        backgroundColor: [
                            'rgba(115, 192, 107, 0.9)',
                            'rgba(255, 159, 64, 0.9)',
                            'rgba(255, 99, 132, 0.9)'
                        ],
                        borderColor: [
                            'rgba(115, 192, 107, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)'
                        ]
                    }]
                },
                options: {
                    responsive: false,
                    legend: {
                        display: false
                    }
                }
            });
        };

        /**
         * Init Form Preferences Expert Doctor
         */
        app.initFormPreferencesExpertDoctor = function () {
            var $select = $('.select-infos-circ');
            $select.on('change', function () {
                app.toggleOtherInfosCirconstanciellesDoctor($(this));
            });
            $select.change();
        };

        /**
         * Toggle Field Other Info Circonstancielle Doctor
         *
         * @param {HTMLElement} element
         */
        app.toggleOtherInfosCirconstanciellesDoctor = function (element) {
            var options = element.find('option:selected');
            var $fieldOtherInfoCirc = $('.bloc-field-other-info-circ');

            if (options.length == 0) {
                $fieldOtherInfoCirc.slideUp();
                $fieldOtherInfoCirc.val('');
            } else {
                options.each(function (index) {
                    if ($(this).attr('class') === 'other') {
                        $fieldOtherInfoCirc.slideDown();
                        return false;
                    } else {
                        $fieldOtherInfoCirc.slideUp();
                        $fieldOtherInfoCirc.val('');
                    }
                });
            }
        };

        /**
         * Init Doctor General Information Form
         */
        app.initDoctorFormGeneralInformation = function () {
            // Field Expert
            var $fieldExpert = $('.field-expert');

            app.toggleDoctorDegrees($fieldExpert);
            $fieldExpert.on('ifChanged', function () {
                app.toggleDoctorDegrees($fieldExpert);
            });

            app.initHideSomething('.check-certified', '.certifed-end-date', 'Checkbox', null, false);
        };

        /**
         * Control Provided Vaccination
         */
        app.toggleDoctorDegrees = function ($fieldExpert) {
            var $otherDoctorsDegrees = $('.bloc-other-doctors-degrees');

            if (!$fieldExpert.is(':checked')) {
                $otherDoctorsDegrees.slideUp();
                $otherDoctorsDegrees.find('select option:selected').removeAttr('selected');
                $otherDoctorsDegrees.find('.select2 .select2-selection__rendered li').each(function () {
                    $(this).remove();
                });
            } else {
                $otherDoctorsDegrees.slideDown();
            }
        };

        /** ================================== End Doctor ======================================= **/

        /** ================================================================================== **/
        /** ================================== Medical Control ================================ **/
        /** ================================================================================== **/

        /**
         * Medical Control List page
         */
        app.initMedicalControListPage = function () {
            app.initButtonFilter($('.btn-filter'), $('#datatable-ctrl'), $('.form-ctrl'), 'control');
            app.initButtonFilter($('.btn-filter-vaccination'), $('#datatable-vacc'), $('.form-vaccination'), 'vaccination');
        };

        app.initBtnShowMedicalControl = function () {
            $('.btn-show-medicalcontrol').each(function () {
                $(this).unbind();
                $(this).on('click', function (e) {
                    e.preventDefault();
                    let updateUrl = $(this).attr('update-access');
                    let $elt = $(this);
                    $.ajax({
                        url: updateUrl,
                        method: 'GET',
                        success: function (data, textStatus, xhr) {
                            if (data.content === 'true') {
                                window.location.href = $elt.attr('href');
                            } else {
                                app.processAfterAjaxResponse(data);
                            }
                        },
                        error: function (xhr, textStatus, codeError) {
                            app.addFlashError('Problème de chargement');
                        }
                    });
                });
            })
        }

        /**
         * Medical Control steps page
         * @param {string} urlEditBillingTable
         * @param {string} currentState
         * @param {string} stateToBill
         * @param {bool} accessNotGranted
         */
        app.initMedicalControl = function (urlEditBillingTable, currentState, stateToBill, accessNotGranted) {
            // Init Collapse Bootstrap
            app.initCollapse('accordion-medical-control');
            app.initCollapse('accordion-medical-control-first-right');
            app.initCollapse('accordion-medical-control-second-right');
            app.initCollapse('accordion-medical-control-third-right');

            // IntroJS
            $('.launch-tuto').on('click', function () {
                app.initIntroJS();
            });

            // jQuery edit table - No data update if state no To bill
            if (currentState === stateToBill) {
                $('#table-billing').Tabledit({
                    url: urlEditBillingTable,
                    editButton: true,
                    deleteButton: false,
                    hideIdentifier: true,
                    dangerClass: '',
                    warningClass: '',
                    mutedClass: '',
                    buttons: {
                        save: {
                            class: 'btn btn-sm btn-success',
                            html: 'Ok'
                        }
                    },
                    columns: {
                        identifier: [0, 'id'],
                        editable: [
                            [3, 'facturation-final'],
                            [5, 'honoraire-final']

                        ]
                    },
                    onDraw: function () {
                        var tdsLastRow = $('.billing-total td');
                        if (tdsLastRow.length > 0) {
                            tdsLastRow.last().empty();
                        }
                    },
                    onSuccess: function (data, textStatus, jqXHR) {
                        // Changement du montant total Facturation
                        if (
                            data.hasOwnProperty('totalFacturationFinal')
                            && data.hasOwnProperty('totalHonoraireFinal')
                            && data.hasOwnProperty('margeLine')
                            && data.hasOwnProperty('margeTotale')
                            && data.hasOwnProperty('idBillingLine')
                            && data.idBillingLine !== 0
                        ) {
                            $('.table-billing__honoraires.final .tabledit-span').text(data.totalHonoraireFinal);
                            $('.table-billing__honoraires.final .tabledit-input').val(data.totalHonoraireFinal);

                            $('.table-billing__facturation.final .tabledit-span').text(data.totalFacturationFinal);
                            $('.table-billing__facturation.final .tabledit-input').val(data.totalFacturationFinal);

                            app.margeBilling(data.margeTotale, $('.marge-totale-billing .satisfaction')); // Marge totale
                            app.margeBilling(data.margeLine, $('.marge-billing-line-' + data.idBillingLine + ' .satisfaction')); // Marge spécifique
                        }

                        app.addFlash('success', 'La facturation a été mise à jour.');
                    },
                    onFail: function (jqXHR, textStatus, errorThrown) {
                        app.addFlash('error', 'La facturation n\'a pas été mise à jour.');
                    }
                });
            }

            // Init step doctor
            app.initStepDoctor();

            // Init choices tel/email/fax/sms
            // app.initChoicesSending(); // TODO : En commentaire car je vais en avoir besoin dans une autre tâche

            if (!accessNotGranted) {
                let url = $('.medical-control-content').attr('data-update-access');
                app.updateMedicalControlAccess(url);
                window.setInterval(function () {
                        app.updateMedicalControlAccess(url);
                    },
                    30 * 1000);
            }

            $('.link-new-tab').each(function () {
                $(this).on('click', function (e) {
                    e.preventDefault();
                    window.open($(this).attr('href'), '_blank');
                });
            });

            $(document)
                .on('initCalendar', function (e) {
                    app.initCalendar();
                    $('.planning-header').parent('.box-header').hide();
                });

            // Reload each block
            $('.ajax-reload').each(function () {
                app.reloadBlockAjax($(this).attr('id'), false);
            });
        };

        /**
         * Met à jour l'acte pour dire que l'utilisateur actuel consulte l'acte
         */
        app.updateMedicalControlAccess = function (url) {
            $.ajax({
                url: url,
                method: 'GET'
            });
        };

        app.initChoicesSending = function () {
            $('.send-doc-generation a').not('.letter').on('click', function (event) {
                event.preventDefault();

                if (confirm('Etes-vous sûr de vouloir effectuer cette action ?')) {
                    var element = $(this);
                    var $loader = $(this).closest('.send-doc-generation').next('.invisible-loader-generation-docs');
                    $loader.removeClass('hide-element');

                    $.ajax({
                        url: element.attr('href'),
                        method: 'GET',
                        success: function (data, textStatus, xhr) {
                            $loader.addClass('hide-element');

                            if (data.hasOwnProperty('email')) {
                                if (data.email) {
                                    alert('L\'e-mail a bien été envoyé');
                                } else {
                                    alert('L\'e-mail n\'a pas été envoyé');
                                }
                            } else if (data.hasOwnProperty('document')) {
                                window.open(data.document, '_blank');
                            }
                        },
                        error: function (xhr, textStatus, codeError) {
                            $loader.addClass('hide-element');

                            app.addFlashError('Problème de chargement');
                        }
                    });
                }
            });
        };

        app.initStepDoctor = function () {
            $('.select-doctor').on('change', function () {
                var optionSelected = $(this).find(':selected');
                var text = '';
                if ($(this).val() != '') {
                    text = '<p><strong>' + optionSelected.text() + '</strong></p>';

                    if (optionSelected.attr('data-address')) {
                        text += '<p>Adresse : ' + optionSelected.attr('data-address') + '</p>';
                    }

                    if (optionSelected.attr('data-tel')) {
                        text += '<p>N° de téléphone : ' + optionSelected.attr('data-tel') + '</p>';
                    }

                    if (optionSelected.attr('data-fax')) {
                        text += '<p>Fax : ' + optionSelected.attr('data-fax') + '</p>';
                    }

                    if (optionSelected.attr('data-email')) {
                        text += '<p>Email : ' + optionSelected.attr('data-email') + '</p>';
                    }
                }

                $('.infos-doctor').html(text);
            });
        };

        /**
         * Replace marge
         *
         * @param {int} marge
         * @param {HTMLElement} domElement
         */
        app.margeBilling = function (marge, domElement) {
            if (marge == 1) { // bad
                domElement
                    .removeClass('good-satisfaction fa-smile-o middle-satisfaction fa-meh-o')
                    .addClass('bad-satisfaction fa-frown-o');
            } else if (marge == 2) { // middle
                domElement
                    .removeClass('good-satisfaction fa-smile-o bad-satisfaction fa-frown-o')
                    .addClass('middle-satisfaction fa-meh-o');
            } else { // good
                domElement
                    .removeClass('middle-satisfaction fa-meh-o bad-satisfaction fa-frown-o')
                    .addClass('good-satisfaction fa-smile-o');
            }
        };

        /**
         * Init if we show the prototype of the Control Organisation div
         */
        app.initMedicalControlOrgaCtrl = function () {
            $('.medical-control-more-orga-ctrl').on('click', function (event) {
                $('.medical-control-div-orga-plus').slideDown();
                $('.medical-control-div-orga').slideUp();
            });
            $('.medical-control-less-orga-ctrl').on('click', function (event) {
                $('.medical-control-div-orga-plus').slideUp();
                $('.medical-control-div-orga').slideDown();
            });
        };

        /**
         * initMedicalControlProblemSelect
         */
        app.initMedicalControlProblemSelect = function () {
            var select = $('.medical-control-address-incomplet-absence');
            var selectProblem = $('.medical-control-address-incomplet-problem');
            app.initMedicalControlMutableSelect(select, selectProblem);
            app.handleMedicalControlProblemSelect(selectProblem, select);
        };

        /**
         * Create a onchange method for a medical control select
         * @param {HTMLElement} select
         * @param {HTMLElement} selectProblem
         */
        app.initMedicalControlMutableSelect = function (select, selectProblem) {
            select.on('change', function (event) {
                app.handleMedicalControlProblemSelect(selectProblem, select);
            });
        };

        /**
         * handleMedicalControlProblemSelect
         * @param {HTMLElement} selectProblem
         * @param {HTMLElement} select
         */
        app.handleMedicalControlProblemSelect = function (selectProblem, select) {
            selectProblem.empty();
            var value = select.val();
            switch (value) {
                case 'Code d\'accès' :
                    selectProblem.append(new Option('Inconnue', 'Inconnue'));
                    selectProblem.append(new Option('Erroné', 'Erroné'));
                    break;
                case 'Téléphone' :
                    selectProblem.append(new Option('Pas de rep tel', 'Pas de rep tel'));
                    selectProblem.append(new Option('Pas attribué', 'Pas attribué'));
                    selectProblem.append(new Option('Mauvais num', 'Mauvais num'));
                    selectProblem.append(new Option('Pas de tel', 'Pas de tel'));
                    break;
                case 'Numérotation' :
                    selectProblem.append(new Option('Num absente', 'Num absente'));
                    selectProblem.append(new Option('Problème num appartement', 'Problème num appartement'));
                    selectProblem.append(new Option('Problème num étage', 'Problème num étage'));
                    selectProblem.append(new Option('Problème num batiment', 'Problème num batiment'));
                    break;
            }
            if (selectProblem.children('option').length === 0) {
                selectProblem.slideUp();
            } else {
                selectProblem.slideDown();
            }
        };

        /**
         *
         * @param {string} selectClass
         */
        app.handleMultipleOnChangeMedicalControl = function (selectClass) {
            var value = $(selectClass + ' option:selected').attr('code');

            var $divJustify = $('.bilan-justify');
            var $divAbsent = $('.bilan-absent');
            var $divAddressErronee = $('.bilan-address-erronee');
            var $divAddressIncomplet = $('.bilan-address-incomplet');
            var $divUnjustify = $('.bilan-unjustify');
            var $divRefus = $('.bilan-refus');
            var $divJustifyAT = $('.bilan-justify-at');

            $divJustify.slideUp();
            $divAbsent.slideUp();
            $divAddressErronee.slideUp();
            $divAddressIncomplet.slideUp();
            $divUnjustify.slideUp();
            $divRefus.slideUp();
            $divJustifyAT.slideUp();

            switch (value) {
                case 'justify' :
                    $divJustify.slideDown();
                    break;
                case 'absent' :
                    $divAbsent.slideDown();
                    break;
                case 'address_erronee' :
                    $divAddressErronee.slideDown();
                    break;
                case 'address_incomplete' :
                    $divAddressIncomplet.slideDown();
                    break;
                case 'refus' :
                    $divRefus.slideDown();
                    break;
                case 'no_justify' :
                    $divUnjustify.slideDown();
                    break;
                case 'justify_at' :
                    $divJustifyAT.slideDown();
                    break;
            }
        };

        /**
         * @param {string} selectClass
         */
        app.initMultipleOnChangeMedicalControl = function (selectClass) {
            $(selectClass).on('change', function (event) {
                app.handleMultipleOnChangeMedicalControl(selectClass);
            });
            app.handleMultipleOnChangeMedicalControl(selectClass);
        };

        /**
         * Change field value to know type of submit
         *
         * @param {HTMLElement} element
         */
        app.changeValueHiddenField = function (element) {
            var value = element.attr('value');
            if (value != undefined && value != null && value != '') {
                $('.medical-control-type-submit').val(value);
            }
        };

        /**
         * Init Control Organisation Form
         */
        app.initFormControlOrganisation = function () {
            // Gestion de la colelction de RDVs
            app.gestionCollectionForm('bloc-prototype-collection', 'control_organisation');
        };

        /**
         * Show/hide field km/amount linked to choice field
         * @param {jQuery} that
         */
        app.toggleBilanKmsAndAmounts = function (that) {
            var classToShow = that.attr('data-field-to-show');
            if (classToShow != '' && classToShow != undefined) {
                var toShow = $('.' + classToShow).closest('.container-linked-field');

                if (that.is(':checked')) {
                    toShow.show();
                } else {
                    toShow.hide();
                }
            }
        };

        /**
         * Init Bilan Kms And Amounts
         */
        app.initBilanKmsAndAmounts = function () {
            $('.control-value').each(function () {
                app.toggleBilanKmsAndAmounts($(this));
                $(this).on('ifChanged', function () {
                    app.toggleBilanKmsAndAmounts($(this));
                });
            });
        };

        /**
         * Show/hide field letter
         * @param {jQuery} that
         */
        app.toggleControlOrganisationLetter = function (that) {
            var toShow = $('.container-letter-type');

            if (that.is(':checked')) {
                toShow.show();
            } else {
                toShow.hide();
                $('.field-letter-type').val('');
                $('.container-num-lrar').hide();
                $('.field-numero-lrar').val('');
            }
        };

        /**
         * Init Control Organisation Letter
         */
        app.initControlOrganisationLetter = function (cssClassRdvSalarie) {
            var $element = $('.' + cssClassRdvSalarie + ' .sending-choice.mail');

            app.toggleControlOrganisationLetter($element);
            $element.on('click', function () {
                app.toggleControlOrganisationLetter($(this));
            });
        };

        /**
         * Init Bilan Contrôle Form
         * @param {string} parentClassBlock
         */
        app.initFormBilanControle = function (parentClassBlock) {
            app.initMedicalControlProblemSelect();
            app.gestionCollectionForm(parentClassBlock);
            var optionValues = new Map();
            $('.bilan-state-select option').each(function () {
                optionValues.set($(this).val(), $(this).text());
            });
            app.handleProlongationBilanForm(optionValues);
            app.initMultipleOnChangeMedicalControl('.control-organisation-select');
            $('.bilan-state-select').on('change', function () {
                app.handleProlongationBilanForm(optionValues);
            });
            app.initHideSomething('.bilan-abs-type-select', '.bilan-absent-comment', 'Select', ['absence-de-reponse', 'absence-confirme'], false);
            app.initHideSomething('.bilan-refus-select', '.bilan-refus-contact', 'Select', 'salarie-averti', false);
            app.initHideSomething('.bilan-state-select', '.bilan-send-types', 'Select',
                ['choice-absence_reponse', 'choice-confirme_tiers', 'choice-confirme_lui', 'choice-no_presented'],
                false
            );
            app.initHideSomething('.icheck-pathology-linked', '.bilan-justify-at-pathology-linked-fields', 'Checkbox', null, false);
            app.initHideSomething('.icheck-pathology-linked', '.bilan-justify-at-pathology-no-linked-fields', 'Checkbox', null, true);
        };

        app.handleProlongationBilanForm = function (options) {
            var values = $('.bilan-state-select').val();
            var maps = options;
            var hasProlongation = false;
            if (Array.isArray(values)) {
                values.forEach(function (key) {
                    if (maps.get(key) === 'Prolongation à prévoir' || maps.get(key) === 'Reprise anticipée < 48h' || maps.get(key) === 'Reprise anticipée > 48h') {
                        $('.bilan-justify-prolongation').slideDown();
                        hasProlongation = true;
                    }
                })
            }
            if (!hasProlongation) {
                $('.bilan-justify-prolongation').slideUp();
            }
        };

        /**
         * Init Bilan Contrôle Form
         * @param {string} parentClassBlock
         */
        app.initFormVaccinationEstimateNeed = function (parentClassBlock) {
            app.gestionCollectionForm(parentClassBlock);
        };

        app.initAutoFillAddressDoctorOrPatient = function () {
            $('.type-rdv .icheck').on('ifChecked', function () {
                var $date = $(this).closest('.bloc-rdv-control-organisation').find('.field-passageDate').closest('.element-form-customer');
                var $startHour = $(this).closest('.bloc-rdv-control-organisation').find('.field-hourStartArrival').closest('.element-form-customer');
                var $endHour = $(this).closest('.bloc-rdv-control-organisation').find('.field-hourEndArrival').closest('.element-form-customer');

                $(this).closest('.type-rdv').find('.icheck').not(this).iCheck('uncheck');

                var $blocAddress = $(this).closest('.bloc-rdv-control-organisation').find('.address-patient');
                var $infosAddresses = $(this).closest('.bloc-rdv-control-organisation').find('.infos-addresses');
                var $containerAddress = $(this).closest('.bloc-rdv-control-organisation').find('.container-address');

                if ($(this).attr('class').indexOf('field-office') != -1) { // Cabinet coché
                    $blocAddress.find('.address').val($infosAddresses.attr('data-addr-doctor-address1'));
                    $blocAddress.find('.additional-address').val($infosAddresses.attr('data-addr-doctor-additional-address1'));
                    $blocAddress.find('.zip').val($infosAddresses.attr('data-addr-doctor-zip1'));
                    $blocAddress.find('.zip').trigger('keyup');
                    $containerAddress.attr('data-id-city', $infosAddresses.attr('data-addr-doctor-id-city1'));

                    $(this).closest('.bloc-rdv-control-organisation').find('.container-change-address-doctor')
                        .attr('data-address-number', $blocAddress.find('.address').val() === $infosAddresses.attr('data-addr-doctor-address' + 2) ? 2 : 1);

                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourStartArrival').val('');
                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourStartArrival').closest('.element-form-customer').hide();
                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourEndArrival').val('');
                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourEndArrival').closest('.element-form-customer').hide();

                    $date.show();
                    $startHour.hide();
                    $endHour.hide();
                } else if ($(this).attr('class').indexOf('field-home') != -1 || $(this).attr('class').indexOf('field-hp') != -1) { // Domicile coché
                    $blocAddress.find('.address').val($infosAddresses.attr('data-addr-patient-address'));
                    $blocAddress.find('.additional-address').val($infosAddresses.attr('data-addr-patient-additional-address'));
                    $blocAddress.find('.zip').val($infosAddresses.attr('data-addr-patient-zip'));
                    $blocAddress.find('.zip').trigger('keyup');
                    $containerAddress.attr('data-id-city', $infosAddresses.attr('data-addr-patient-id-city'));

                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourStartArrival').closest('.element-form-customer').show();
                    $(this).closest('.bloc-rdv-control-organisation').find('.field-hourEndArrival').closest('.element-form-customer').show();

                    if ($(this).attr('class').indexOf('field-hp') != -1) {
                        $date.hide();
                        $startHour.hide();
                        $endHour.hide();
                    }
                }
            });
        };

        /**
         * Add/delete in collection (just in Bilan form now)
         * @param {string} parentClassBlock
         * @param {string} context
         */
        app.gestionCollectionForm = function (parentClassBlock, context) {
            app.initAutoFillAddressDoctorOrPatient();

            var callInitAutocompleteCityFromZip = true;
            if (context === 'control_organisation') {
                callInitAutocompleteCityFromZip = false;
            }

            // Gestion de la collection des contacts
            $('a[data-role="btn-add"]').on('click', function (event) {
                var collectionHolder = $('#' + $(this).attr('data-target'));
                var prototype = collectionHolder.attr('data-prototype');
                var form = prototype.replace(/__name__/g, collectionHolder.children().length);

                collectionHolder.append(form);

                collectionHolder.find('select').not('.not-select2 select').not('.not-select2').select2({
                    language: "fr",
                    theme: "classic",
                    width: '100%'
                });

                collectionHolder.find('input.datetimepicker').datetimepicker({
                    locale: 'fr',
                    format: 'DD/MM/YYYY HH:mm'
                });

                collectionHolder.find('input.datepicker').datetimepicker({
                    locale: 'fr',
                    format: 'DD/MM/YYYY'
                });

                collectionHolder.find('input.timepicker').datetimepicker({
                    locale: 'fr',
                    format: 'HH:mm'
                });

                app.initFloatLabels();
                app.initIcheck();

                if (context === 'control_organisation') {
                    app.initAutocompleteCityFromZip(false, true, true);
                    callInitAutocompleteCityFromZip = true;

                    app.initAutoFillAddressDoctorOrPatient();
                } else {
                    app.initAutocompleteCityFromZip(true, false, true);
                }

                app.removeBlocCollectionForm(parentClassBlock);

                var $lastBloc = collectionHolder.find('.bloc-rdv-control-organisation').last();
                $lastBloc.find('.type-rdv .icheck').on('ifChecked', function () {
                    $(this).closest('.type-rdv').find('.icheck').not(this).iCheck('uncheck');

                    if ($(this).attr('class').indexOf('field-office') != -1) { // Cabinet coché
                        $lastBloc.find('.container-change-address-doctor').show();
                    } else if ($(this).attr('class').indexOf('field-home') != -1) { // Domicile coché
                        $lastBloc.find('.container-change-address-doctor').hide();
                    } else if ($(this).attr('class').indexOf('field-hp') != -1) { // HP coché
                        $lastBloc.find('.container-change-address-doctor').hide();
                    }
                });

                app.initRdvControlOrganisation($lastBloc);

                return false;
            });

            app.removeBlocCollectionForm(parentClassBlock);

            if (context === 'control_organisation') {
                $('.bloc-rdv-control-organisation').each(function () {
                    app.initRdvControlOrganisation($(this));
                });
            }
        };

        /**
         * Initialisation d'un nouveau RDV dans l'étape Organisation d'un acte
         * @param {jQuery} $bloc
         */
        app.initRdvControlOrganisation = function($bloc) {
            $bloc.find('.type-rdv .icheck').on('ifChecked', function () {
                $(this).closest('.type-rdv').find('.icheck').not(this).iCheck('uncheck');

                if ($(this).attr('class').indexOf('field-office') != -1) { // Cabinet coché
                    $bloc.find('.field-passageDate').closest('.element-form-customer').show();
                    $bloc.find('.field-hourStartArrival').closest('.element-form-customer').hide();
                    $bloc.find('.field-hourEndArrival').closest('.element-form-customer').hide();
                    $bloc.find('.container-change-address-doctor').show();
                } else if ($(this).attr('class').indexOf('field-home') != -1) { // Domicile coché
                    $bloc.find('.field-passageDate').closest('.element-form-customer').show();
                    $bloc.find('.field-hourStartArrival').closest('.element-form-customer').show();
                    $bloc.find('.field-hourEndArrival').closest('.element-form-customer').show();
                    $bloc.find('.container-change-address-doctor').hide();
                } else if ($(this).attr('class').indexOf('field-hp') != -1) { // HP coché
                    $bloc.find('.field-passageDate').closest('.element-form-customer').hide();
                    $bloc.find('.field-hourStartArrival').closest('.element-form-customer').hide();
                    $bloc.find('.field-hourEndArrival').closest('.element-form-customer').hide();
                    $bloc.find('.container-change-address-doctor').hide();
                }
            });

            // Clic sur le bouton de changement d'adresse du médecin
            $bloc.find('.change-address').on('click', function () {
                var currentAddressNumber = $(this).closest('.container-change-address-doctor').attr('data-address-number');
                if (currentAddressNumber === undefined) {
                    if ($bloc.find('.container-address').attr('data-address-number') !== undefined) {
                        currentAddressNumber = $bloc.find('.container-address').attr('data-address-number');
                    } else {
                        currentAddressNumber = 1;
                    }
                }

                var newAddressNumber = currentAddressNumber == 1 ? 2 : 1;
                var $blocAddress = $(this).closest('.bloc-rdv-control-organisation').find('.address-patient');
                var $infosAddresses = $(this).closest('.bloc-rdv-control-organisation').find('.infos-addresses');
                var $containerAddress = $(this).closest('.bloc-rdv-control-organisation').find('.container-address');

                $blocAddress.find('.address').val($infosAddresses.attr('data-addr-doctor-address' + newAddressNumber));
                $blocAddress.find('.additional-address').val($infosAddresses.attr('data-addr-doctor-additional-address' + newAddressNumber));
                $blocAddress.find('.zip').val($infosAddresses.attr('data-addr-doctor-zip' + newAddressNumber));
                $blocAddress.find('.zip').trigger('keyup');
                $containerAddress.attr('data-id-city', $infosAddresses.attr('data-addr-doctor-id-city' + newAddressNumber));

                $(this).closest('.container-change-address-doctor').attr('data-address-number', newAddressNumber);
            });

            if ($bloc.find('.type-rdv .field-office').is(':checked')) { // Cabinet coché
                $bloc.find('.field-passageDate').closest('.element-form-customer').show();
                $bloc.find('.field-hourStartArrival').closest('.element-form-customer').hide();
                $bloc.find('.field-hourEndArrival').closest('.element-form-customer').hide();
                $bloc.find('.container-change-address-doctor').show();

                const $infosAddresses = $(this).closest('.bloc-rdv-control-organisation').find('.infos-addresses');
                const $blocAddress = $(this).closest('.bloc-rdv-control-organisation').find('.address-patient');

                $(this).closest('.bloc-rdv-control-organisation').find('.container-change-address-doctor')
                    .attr('data-address-number', $blocAddress.find('.address').val() === $infosAddresses.attr('data-addr-doctor-address' + 2) ? 2 : 1);
            } else if ($bloc.find('.type-rdv .field-home').is(':checked')) { // Domicile coché
                $bloc.find('.field-passageDate').closest('.element-form-customer').show();
                $bloc.find('.field-hourStartArrival').closest('.element-form-customer').show();
                $bloc.find('.field-hourEndArrival').closest('.element-form-customer').show();
                $bloc.find('.container-change-address-doctor').hide();
            } else if ($bloc.find('.type-rdv .field-hp').is(':checked')) {
                $bloc.find('.field-passageDate').closest('.element-form-customer').hide();
                $bloc.find('.field-hourStartArrival').closest('.element-form-customer').hide();
                $bloc.find('.field-hourEndArrival').closest('.element-form-customer').hide();
                $bloc.find('.container-change-address-doctor').hide();
            }
        };

        /**
         * Delete block bilan contact
         * @param {string} parentClassBlock
         */
        app.removeBlocCollectionForm = function (parentClassBlock) {
            $('a[data-role="btn-remove"]').on('click', function (event) {
                $(this).closest('.' + parentClassBlock).remove();

                return false;
            });
        };

        /**
         * Init Work Stoppage Form (step 2)
         * @param {string} valueMr
         * @param {string} valueMme
         * @param {integer} nbRef
         * @param {string} idWorkStoppage
         */
        app.initWorkStoppageForm = function (valueMr, valueMme, nbRef, idWorkStoppage) {
            var select = $('.existing-patient');
            let $blocExistingPatientData = $('.bloc-existing-patient-data');

            select.on('change', function (event) {
                var val = $(this).val();
                if (val != '' && val != undefined && val != null) {
                    $('.bloc-new-patient').slideUp();
                } else {
                    $('.bloc-new-patient').slideDown();
                    $('#add-another-ref').unbind();
                    app.initAdditionnalCollections(nbRef);
                }
            });

            select.change();

            app.initHideSomething('.select-workstoppage-type', '.hidden-maladie', 'Select', 'choice-maladie', true);

            var $selectCustomer = $('.select-customer');
            $selectCustomer.on('change', function () {
                if ($('.select-customer').val() !== "") {
                    app.handleContactExceptionnel('newPatient', idWorkStoppage);
                }
            });
            $selectCustomer.change();

            select.on('change', function () {
                app.handleSelectPatient(idWorkStoppage, true, nbRef);
                $blocExistingPatientData.slideUp();
                $blocExistingPatientData.html('');
            });
            app.handleSelectPatient(idWorkStoppage, true, nbRef);

            // Affichage du formulaire de contact exceptionnel
            app.displayContactExceptionnelForm();

            // Init edit form employee
            app.initEditFormEmployee($blocExistingPatientData, select, nbRef);
        };

        /**
         * Init Work Stoppage Form (step 2)
         * @param {string} valueMr
         * @param {string} valueMme
         * @param {int} nbRef
         */
        app.initWorkStoppageFormCoteClient = function (valueMr, valueMme, nbRef) {
            var select = $('.existing-patient');
            let $blocExistingPatientData = $('.bloc-existing-patient-data');

            select.on('change', function (event) {
                var val = $(this).val();
                if (val != '' && val != undefined && val != null) {
                    $('.bloc-new-patient').slideUp();
                } else {
                    $('.bloc-new-patient').slideDown();
                }
            });

            select.change();

            app.initHideSomething('.select-workstoppage-type', '.hidden-maladie', 'Select', 'choice-maladie', true);
            app.initHideSomething('.select-exit-type', '.exit-employee', 'Select', 'sortie-libre', false);
            app.initHideSomething('.check-hours-provided', '.hours-provided', 'Checkbox', null, false);

            select.on('change', function () {
                app.handleSelectPatient(null, false, nbRef);
                $blocExistingPatientData.slideUp();
                $blocExistingPatientData.html('');
            });
            app.handleSelectPatient(null, false, nbRef);

            // Affichage du formulaire de contact exceptionnel
            app.displayContactExceptionnelForm();

            // Init edit form employee
            app.initEditFormEmployee($blocExistingPatientData, select, nbRef);
        };

        app.initEditFormEmployee = function ($blocExistingPatientData, select, nbRef) {
            $('.form-employee__btn-edit').on('click', function (e) {
                e.preventDefault();
                $blocExistingPatientData.slideUp();
                $.ajax({
                    url: $(this).attr('href'),
                    method: 'GET',
                    success: function (data, textStatus, xhr) {
                        $blocExistingPatientData.html(data);
                        $blocExistingPatientData.slideDown();
                        initJsForEditPatient($blocExistingPatientData, select)
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                });
            });



            app.initAdditionnalCollections(nbRef);

            // Si les sorties sont autorisées sans restrictions d'horaires, on affiche le formulaire de note de service
            app.initHideSomething('.show-hide-service-note-group', '.service-note', 'Radio', '.show-hide-service-note', false);

            // Si la note de service est difusée en interne, on affiche les formulaires des horaires
            app.initHideSomething('.show-hide-exit-or-control-group', '.exit-employee', 'Radio', '.show-hide-exit', false);

            // Si la note de service n'est pas difusée en interne, on affiche les formulaires de contrôle
            app.initHideSomething('.show-hide-exit-or-control-group', '.control-employee', 'Radio', '.show-hide-control', false);

            // Si les horaires ne sont pas fournis par le salarié, on affiche les champs pour la plage horaire
            app.initHideSomething('.check-hours-provided', '.hidden-free-exit-type', 'Checkbox', null, false);

            app.matchInputs('.match-control-appointment-1', null, null);
            app.matchInputs('.match-control-appointment-2', null, null);
            app.matchInputs('.check-hours-not-provided',true, '.check-hours-provided');
            app.matchInputs('.check-hours-provided',true, '.check-hours-not-provided');
        };

        function initJsForEditPatient($blocExistingPatientData, select) {
            app.initDatetimePicker();
            app.initDatepicker();
            app.initSelect2();
            app.initAutocompleteCityFromZip(true, false, true);

            var floatlabels = new FloatLabels('form'); //float-labels-js
            $('.btn-success').on('click', function (e) {
                e.preventDefault();
                $.ajax({
                    url: select.find(":selected").attr('ajax-url'),
                    method: 'POST',
                    data: $('.form-employee__existing-patient').serialize(),
                    success: function (data, textStatus, xhr) {
                        const responseContentType = xhr.getResponseHeader('Content-Type')
                        if (responseContentType.startsWith('text/html')) {
                            app.addFlashError('Une erreur est survenue, veuillez revérifier le formulaire de modification du salarié');
                            $blocExistingPatientData.html(data);
                            initJsForEditPatient($blocExistingPatientData, select)
                        } else {
                            $blocExistingPatientData.slideUp();
                        }
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                });
            });
        }

        /**
         * Init the addionnals references collections form
         * @param {integer} nbRef
         */
        app.initAdditionnalCollections = function (nbRef) {
            // Bloc additionnals references
            let refCount = nbRef;

            $('#add-another-ref').unbind();
            $('#add-another-ref').click(function () {
                let $refList = $('#ref-fields-list');
                let newWidget = $refList.attr('data-prototype');
                newWidget = newWidget.replace(/__additionnal_refs__/g, refCount);
                refCount++;
                let newLi = $('<tr class="bloc-prototype-collection"></tr>').html(newWidget);
                newLi.appendTo($refList);

                app.removeBlocCollectionForm('bloc-prototype-collection');
                app.initFloatLabels();
                return false;
            });
            app.removeBlocCollectionForm('bloc-prototype-collection');
        };

        app.handleSelectPatient = function (idWorkStoppage, executeHandleContactExceptionnel, nbRef) {
            let $existingPatient = $('.existing-patient');
            let $fieldBtnEdit = $('.form-employee__btn-edit');

            if ($existingPatient.val() !== "") {
                if (executeHandleContactExceptionnel) {
                    app.handleContactExceptionnel('patient', idWorkStoppage);
                }

                $fieldBtnEdit.slideDown();
                $fieldBtnEdit.attr('href', $existingPatient.find(":selected").attr('ajax-url'));
            } else {
                $fieldBtnEdit.slideUp();
                $('.bloc-existing-patient-data').slideUp();
            }
        };

        app.displayContactExceptionnelForm = function () {
            let $cbox = $('.display-contact-exceptionnel');
            let $form = $('.bloc-contact-exceptionnel');

            app.handleDisplayContactExceptionnelForm($cbox, $form);
            $cbox.on('ifChanged', function () {
                app.handleDisplayContactExceptionnelForm($(this), $form);
            });
        };

        app.handleDisplayContactExceptionnelForm = function ($cbox, $form) {
            if ($cbox.is(':checked')) {
                $form.slideDown();
            } else {
                $form.find('input').val('');
                $form.slideUp();
            }
        };

        /**
         * @param context
         * @param idWorkStoppage
         */
        app.handleContactExceptionnel = function (context, idWorkStoppage) {
            var url = $('.form-work-stoppage').attr('contact-data-url');
            var text = "";
            if (context === 'newPatient') {
                text = $('.select-customer option:selected').text().split(' ');
                url += '/' + text[0]
            } else {
                text = $('.existing-patient option:selected').text().split(' - ');
                url += '/' + text[text.length - 1];
                if (idWorkStoppage != '' && idWorkStoppage != undefined) {
                    url += '?work_stoppage=' + idWorkStoppage;
                }
            }

            $.ajax({
                url: url,
                method: 'GET',
                success: function (data, textStatus, xhr) {
                    $('.wk-customer').empty();
                    $('.wk-customer').append('<option value="">Contact acte en cours</option>');
                    for (var index in data) {
                        var selected = "";
                        if (data[index].selected == 1) {
                            selected = "selected";
                        }

                        $('.wk-customer').append(
                            '<option value="' + data[index].id + '" ' + selected + '>' + data[index].name + '</option>'
                        )
                    }

                    var contactSelected = $('.wk-customer').closest('.form-work-stoppage').attr('data-contact-selected');
                    if (contactSelected != null && contactSelected != '') {
                        $('.wk-customer').val(contactSelected);
                    }
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        /**
         * Init New Medical Control Form
         * @param {string} typeVaccination
         */
        app.initCreateMedicalControlForm = function (typeVaccination) {
            var $controlType = $('.select-control-type');
            $controlType.on('change', function (event) {
                if ($(this).find('option:selected').attr('class') === typeVaccination) {
                    $('.container-customer-field').collapse('show');
                } else {
                    $('.container-customer-field').collapse('hide');
                }
            });

            $controlType.change();
        };

        app.initBilanForm = function () {
            // Cacher/afficher informations à droite (kms et montants)
            app.initBilanKmsAndAmounts();
            app.initFormBilanControle('bloc-prototype-collection');
        };

        app.initCreateCustomerMedicalControlForm = function () {
            var url = $('.form-generated').attr('data-url');
            $('.select-control-type').on('change', function (e) {
                app.handleCreateCustomerMedicalControlForm(this, url);
            });
        };

        app.handleCreateCustomerMedicalControlForm = function (elt, url) {
            switch ($(elt).find('option:selected').attr('class')) {
                case 'ctrl-domicile' :
                    app.loadFormFromAjax(url, 'ctrl-domicile');
                    break;
                case 'ctrl-cabinet' :
                    app.loadFormFromAjax(url, 'ctrl-cabinet');
                    break;
                case 'ctrl-at' :
                    app.loadFormFromAjax(url, 'ctrl-at');
                    break;
                case 'ctrl-eval' :
                    app.loadFormFromAjax(url, 'ctrl-eval');
                    break;
                case 'ctrl-expertise' :
                    app.loadFormFromAjax(url, 'ctrl-expertise');
                    break;
                case 'ctrl-vaccination' :
                    app.loadFormFromAjax(url, 'ctrl-vaccination');
                    break;
                default :
                    var $form = $('.form-generated');
                    $form.slideUp();
                    $form.html('');
                    break;
            }
        };

        app.initCityAjaxRequest = function () {
            var url = $('.custom-select-ajax-perso').attr('data-route-ajax');

            $('.custom-select-ajax-perso').select2({
                language: "fr",
                width: '100%',
                ajax: {
                    url: url,
                    dataType: 'json',
                    data: function (params) {
                        return {
                            q: params.term, // search term
                            page: params.page
                        };
                    },
                    processResults: function (data) {
                        // Tranforms the top-level key of the response object from 'items' to 'results'
                        return {
                            results: data
                        };
                    },
                    cache: true
                },
                templateResult: select2FormatDisplay,
                templateSelection: select2FormatDisplay
            });
        };

        app.initFormAfterLoadSucceed = function (code) {
            const initForm = function (code) {
                app.initIcheck(); //iCheck
                app.initDatepicker(); //Date picker
                app.initTimePicker(); //Time picker
                app.initSelect2(); // Select2
                app.initPreventStringCharacters();
                app.initFieldsDatesOnClickIcon();
                if (code !== 'ctrl-vaccination') {
                    app.initCityAjaxRequest();
                } else {
                    app.initFormVaccinationEstimateNeed('bloc-prototype-collection');
                    app.initHideSomething('.vaccination-other-address', '.div-other-address', 'Checkbox', null, false);
                }
                app.initAjaxForm();
                app.initFloatLabels(); //float-labels-js
                app.initAutocompleteCityFromZip(true, true, true);
                $('input.zip').trigger('keyup');
            };
            initForm(code);

            const $btnModalSubmit = $('.admin-modal_submit');
            $btnModalSubmit.unbind();
            $btnModalSubmit.on('click', function (e) {
                e.preventDefault();
                const $ajaxFormGenerated = $('.ajax-form-generated');

                $.ajax({
                    url: $ajaxFormGenerated.attr('action') ? $ajaxFormGenerated.attr('action') : $('form').attr('action'),
                    data: $ajaxFormGenerated.attr('action') ? $ajaxFormGenerated.serialize() : $('form').serialize(),
                    method: 'POST',
                    success: function (data, textStatus, xhr) {
                        app.processAfterAjaxResponse(data);
                        $ajaxFormGenerated.attr('action') ? $ajaxFormGenerated.html(data) : $('form').html(data)
                        initForm(code);
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                })
            });
        };

        app.loadFormFromAjax = function (path, code) {
            path += '/' + code;
            $('.fl-form').attr('action', path);
            var $form = $('.form-generated');
            var pathSpinnerImg = $form.attr('data-path-spinner');
            $form.html('<img src="' + pathSpinnerImg + '" width="50px" height="50px" />');
            $.ajax({
                url: path,
                method: 'GET',
                success: function (data, textStatus, xhr) {
                    $form.html(data);
                    $form.slideDown();
                    app.initFormAfterLoadSucceed(code);
                },
                error: function (xhr, textStatus, codeError) {
                    app.addFlashError('Problème de chargement');
                }
            });
        };

        /**
         * Init Bilan Vaccination Form
         */
        app.initBilanVaccinationForm = function () {
            var $choiceLogisticsCosts = $('.choice-logistics-costs');
            var $blocLogisticsCosts = $('.bilan-vaccination-logistics-costs').closest('.container-linked-field');

            if ($choiceLogisticsCosts.is(':checked')) {
                $blocLogisticsCosts.show();
            } else {
                $blocLogisticsCosts.hide();
                $blocLogisticsCosts.find('input').val('');
            }

            app.initHideSomething('.check-bilan-vaccination-km', '.bilan-vaccination-km', 'Checkbox', null, false);
            app.initHideSomething('.check-bilan-vaccination-amount', '.bilan-vaccination-amount', 'Checkbox', null, false);

            $choiceLogisticsCosts.on('ifChanged', function () {
                if ($(this).is(':checked')) {
                    $blocLogisticsCosts.show();
                } else {
                    $blocLogisticsCosts.hide();
                    $blocLogisticsCosts.find('input').val('');
                }
            });
        };

        /** ================================== End MedicalControl ================================= **/

        /** ================================== Home Dashboard    ================================= **/

        /**
         * Init the dashboard
         */
        app.initDashboard = function () {

            // Reload each block
            $('.ajax-reload').each(function () {
                app.reloadBlockAjax($(this).attr('id'), false);
            });

            // Listeners
            $(document)
                .on('initKnob', function (e) {
                    app.initKnob();
                })
                .on('initDashboardDonut', function (e) {
                    app.initDatepicker();
                    var colors = {
                        background: [
                            'rgba(165, 181, 199, 1)',
                            'rgba(131, 163, 199, 1)',
                            'rgba(155, 209, 186, 1)',
                            'rgba(184, 158, 189, 1)',
                            'rgba(181, 221, 116, 1)',
                        ],
                        border: [
                            'rgba(165, 181, 199, 0.5)',
                            'rgba(131, 163, 199, 0.5)',
                            'rgba(155, 209, 186, 0.5)',
                            'rgba(184, 158, 189, 0.5)',
                            'rgba(181, 221, 116, 0.5)',
                        ]
                    };
                    app.chartBuilder('donut-percent-acts', '.donut-content-percent', '# d\'actes(s)', 'pie', true, colors);
                    $('.home__btn-filter-dashboard').on('click', function (e) {
                        e.preventDefault();
                        var $dateStart = $('.dashboard-filter-start-date').val();
                        var $dateEnd = $('.dashboard-filter-end-date').val();

                        app.chartUpdater($('#donut-percent-acts'), '.donut-content-percent', $dateStart, $dateEnd);
                    });
                })
                .on('initDashboardActInfoBloc', function (e) {
                    app.initDashboardActInfoBloc();
                })
                .on('initStatsReporting', function (e) {
                    app.initDatepicker();
                    const $dashboardDirectionFilter = $('.home__btn-filter-dashboard-direction');
                    $dashboardDirectionFilter.on('click', function (e) {
                        e.preventDefault();
                        const $form = $('.form-search-reporting');
                        const url = $form.attr('action');
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: $form.serialize(),
                            success: function (data) {
                                $('.result-nb-disabled').html(data);
                            }
                        });
                    });
                    $dashboardDirectionFilter.click();
                })
                .on('initDoubleDonut', function (e) {
                    app.initDatepicker();
                    var colors = {
                        background: [
                            'rgba(191, 36, 24, 1)',
                            'rgba(229, 201, 173, 1)',
                            'rgba(255, 180, 106, 1)',
                            'rgba(255, 137, 21, 1)',
                            'rgba(161, 202, 90, 1)',
                        ],
                        border: [
                            'rgba(191, 36, 24, 0.5)',
                            'rgba(229, 201, 173, 0.5)',
                            'rgba(255, 180, 106, 0.5)',
                            'rgba(255, 137, 21, 0.5)',
                            'rgba(161, 202, 90, 0.5)',
                        ],
                    };
                    app.initGeneralStatsDonut(null, null, colors);
                    $('.home__btn-filter-donut').on('click', function (e) {
                        e.preventDefault();
                        var $dateStart = $('#accordion-bloc-stats-generales .filter-date-start').val();
                        var $dateEnd = $('#accordion-bloc-stats-generales .filter-date-end').val();

                        app.chartUpdater($('#chart-bar-acts-cancelled'), '.chart-bar-content', $dateStart, $dateEnd);
                        app.chartUpdater($('#donut-origin'), '.donut-origin-content', $dateStart, $dateEnd);
                        app.chartUpdater($('#donut-bilan'), '.donut-content', $dateStart, $dateEnd);

                    });
                })
                .on('initTurnOverForm', function () {
                    const $filterSubmit = $('.home__btn-filter-turnover');
                    const $form = $('.form-dashboard-turn-over');
                    $filterSubmit.unbind();

                    app.initDatepicker();
                    $filterSubmit.on('click', function (e) {
                        e.preventDefault();
                        $.ajax({
                            type: "POST",
                            url: $form.attr('action'),
                            data: $form.serialize(),
                            success: function (data) {
                                $('#box-evolution-turnover-direction').html(data);
                                $(document).trigger('initTurnOverForm');
                            },
                            error: function () {
                                app.addFlashError('Impossible de filtrer le tableau d\'évolution du CA');
                            }
                        });
                    })
                }).on('initDashboardCustomer', function (e) {
                app.initDataTables();
                var colors = {
                    background: [
                        'rgba(29, 178, 183, 1)',
                        'rgba(167, 43, 43, 1)',
                    ],
                    border: [
                        'rgba(29, 178, 183, 1)',
                        'rgba(167, 43, 43, 1)',
                    ]
                };
                app.chartBuilder('donut-comparison', '.donut-comparison-content', '# de contrôles', 'doughnut', false, colors, null, $('.filter-date-start').val(), $('.filter-date-end').val());
                app.initDatetimePicker();
                app.initDatepicker();
                var floatlabels = new FloatLabels('form'); //float-labels-js
                app.initButtonFilter($('.btn-filter'), $('#datatable-ctrl'), $('.form-ctrl'), 'control-customer');
                app.initButtonFilter($('.btn-filter-vaccination'), $('#datatable-vacc'), $('.form-vaccination'), 'vaccination-customer');
                $('.home__btn-filter-dashboard').on('click', function (e) {
                    e.preventDefault();
                    const $form = $('.form-period');
                    $.ajax({
                        type: "POST",
                        url: $form.attr('action'),
                        data: $form.serialize(),
                        success: function (data) {
                            $('#customer-dashboard').html(data);
                            $(document).trigger('initDashboardCustomer');
                        }
                    });
                })
            });
        };

        app.initGeneralStatsDonut = function (dateStart, dateEnd, colors) {
            app.chartBuilder('donut-origin', '.donut-origin-content', '# de clients(s)', 'doughnut', true, null, null, dateStart, dateEnd);
            app.chartBuilder('donut-bilan', '.donut-content', '# de bilan(s)', 'doughnut', true, colors, true, dateStart, dateEnd);
            app.chartBuilder('chart-bar-acts-cancelled', '.chart-bar-content', '# d\'annulation(s)', 'doughnut', true, null, null, dateStart, dateEnd);
        };

        /**
         * @param $elt
         * @param $datatable
         * @param $form
         * @param $dataTableType
         * @param triggerClick
         */
        app.initButtonFilter = function ($elt, $datatable, $form, $dataTableType, triggerClick = true) {
            $.fn.dataTable.moment('DD/MM/YYYY');
            $elt.on('click', function (e) {
                    e.preventDefault();

                    if ($form.hasClass('doctor')) { // formulaire de filtrage du cahier comptable médecins pour éviter de tout charger à l'arrivée sur la page
                        let requiredFieldsAreEmpty = [];
                        $form.find('input[required="required"]').each(function() {
                            if ('' === $(this).val()) {
                                requiredFieldsAreEmpty.push($(this).attr('placeholder'));
                                return;
                            }
                        });

                        if (requiredFieldsAreEmpty.length > 0) {
                            alert('Veuillez saisir le(s) champ(s) : '+requiredFieldsAreEmpty.join(', '));
                            return;
                        }
                    }

                    var url = $form.attr('action');
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: $form.serialize(),
                        success: function (data) {
                            var $dataTable = $datatable;
                            var types = data.types;
                            $dataTable.dataTable().fnDestroy();
                            var dataTableLangageParams = app.getConfigDatatable();
                            let params = {
                                "createdRow": function (row, data, dataIndex) {
                                    if ($dataTableType === 'control') {
                                        $(row).addClass('ctrl-row ctrl-' + data[0]);
                                        $(row).find('td').eq(0)
                                            .addClass('avct__' + data[0])
                                            .end().end()
                                    } else {
                                        if (types && !types[dataIndex]) {
                                            $(row).addClass($dataTableType + '-disabled');
                                        }
                                    }
                                },
                                "data": data.data,
                                "bLengthChange": true,
                                "oLanguage": dataTableLangageParams,
                                "fnDrawCallback": function () {
                                    app.initBtnShowMedicalControl();
                                    app.showAccountBookRowDetails();
                                    app.saveBillPaymentDate();
                                    app.saveFeePaymentTracking();
                                    app.initCheckboxPrioritize();
                                    app.initOrderForm();
                                },
                            };

                            if ($datatable.attr('data-export-excel') == 'true') {
                                params.dom = 'Bfrtip';
                                var buttonCommon = {
                                    exportOptions: {
                                        format: {
                                            body: function (data, row, column, node) {
                                                return $(data).length > 0 ? $(data).filter('.brut-value').val() : data;
                                            }
                                        },
                                        // Filtrage des colonnes ayant le data-attibute 'data-exclue-export' pour ne pas les exportés
                                        columns: function (data, row, column, node) {
                                            if (!column.hasAttribute('data-exclue-export')) {
                                                return true;
                                            }
                                        },
                                    }
                                };
                                params.buttons = [$.extend(true, {}, buttonCommon, {
                                    extend: 'excelHtml5',
                                    footer: true,
                                    title: $datatable.attr('data-export-title') != '' && $datatable.attr('data-export-title') != null && $datatable.attr('data-export-title') != undefined
                                        ? $datatable.attr('data-export-title')
                                        : 'Export'
                                })];
                            }

                            if ($datatable.attr('data-total-line') == 'true') {
                                params.footerCallback = function (row, data, start, end, display) {
                                    var api = this.api();

                                    api.columns('.sum', {
                                        page: 'all'
                                    }).every(function () {
                                        var sum = this
                                            .data()
                                            .reduce(function (a, b) {
                                                if ($(a).length && $(a).filter('.brut-value').length) {
                                                    a = $(a).filter('.brut-value').val();
                                                }
                                                if ($(b).length && $(b).filter('.brut-value').length) {
                                                    b = $(b).filter('.brut-value').val();
                                                }

                                                var x = parseFloat(a) || 0;
                                                var y = parseFloat(b) || 0;
                                                return (x + y).toFixed(2);
                                            }, 0);

                                        $(this.footer()).html(sum);
                                    });
                                }
                            }

                            if ($dataTableType === 'control') {
                                params.columns = [
                                    {"width": "1%"},
                                    {"width": "1%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "7%"},
                                    {"width": "15%"},
                                    {"width": "2%"},
                                    {"width": "7%"},
                                    {"width": "7%"}
                                ];
                            }

                            const pagesWhereToolsGeneratedByClient = ['vaccination', 'control', 'doctor', 'customer'];

                            if (pagesWhereToolsGeneratedByClient.indexOf($dataTableType) != -1) {
                                const nbUrlAttributes = ($dataTableType === 'vaccination' || $dataTableType === 'control') ? 4 : 2;
                                let columns = [];
                                if ($dataTableType !== 'comptable-book') {
                                    columns.push({
                                        "targets": data.data[0] ? Object.keys(data.data[0]).length - nbUrlAttributes : null,
                                        "createdCell": function (td, cellData, rowData, row, col) {
                                            $(td).html("<a href=\"" + rowData.routeShow + "\" class=\"btn btn-flat btn-primary " + ($dataTableType === 'control' || $dataTableType === 'vaccination' ? "btn-show-medicalcontrol\" update-access=\"" + rowData.routeUpdateAccess + "\"" : "\"") + " data-modal-validation=\"1\"><span class=\"fa fa-arrow-right\"></span></a>" +
                                                " <a href=\" " + rowData.routeDelete + " \" class=\"btn btn-flat btn-danger confirm   \" data-message=\"Etes vous sur de vouloir supprimer ?\" data-modal-validation=\"1\">" +
                                                "<span class=\"fa fa-trash\"></span></a>");
                                        }
                                    });
                                }
                                if ($dataTableType === 'control' || $dataTableType === 'vaccination') {
                                    columns.push({
                                        "targets": 1,
                                        "createdCell": function (td, cellData, rowData, row, col) {
                                            $(td).html("<span class='hidden'>" + rowData[1] + "</span>" +
                                                "<input type='checkbox' class='icheck check-priority' data-url='" + rowData.routePrioritize + "' data-checked='" + rowData[1] + "'>");
                                        }
                                    });
                                }
                                params.columnDefs = columns;

                                $.fn.dataTable.ext.errMode = 'none';
                            }

                            $dataTable.dataTable(params);
                        }
                    });
                }
            );

            if (triggerClick) {
                $elt.click();
            }

            $elt.closest('form').find(':checkbox').on('ifChanged', function () {
                $elt.click();
            })
        }
        ;

        app.initCheckboxPrioritize = function () {
            $('.check-priority').each(function () {
                $(this).unbind();
                if ($(this).attr('data-checked') === 'true') {
                    $(this).attr("checked", true);
                    $(this).iCheck('update');
                    const $parentTr = $(this).closest('tr');
                    $parentTr.removeClass();
                    $parentTr.addClass('ctrl-row ctrl-J6')
                }
                $(this).on('ifChanged', function (e) {
                    if ($(this).is(':checked')) {
                        const $parentTr = $(this).closest('tr');
                        $parentTr.removeClass();
                        $parentTr.addClass('ctrl-row ctrl-J6')
                    } else {
                        const $parentTr = $(this).closest('tr');
                        $parentTr.removeClass();
                        $parentTr.addClass('ctrl-row ctrl-J0')
                    }
                    $.ajax({
                        type: "GET",
                        url: $(this).attr('data-url'),
                        success: function (data) {

                        }
                    });
                });
            })
        };

        app.initDashboardActInfoBloc = function () {
            $(document).on('click', '.act-info-modal', function (e) {
                e.preventDefault();
                var url = $(this).attr('href');
                var title = $(this).attr('modal-label');

                var footer = '<button type="button" class="btn btn-default" data-dismiss="modal" >Retour</button>';

                $.get(url, function (data) {
                    app.createModal(title, data, true, footer);
                    initAjaxForm(null, true);
                });
            });
        };

        app.initKnob = function () {
            $(".dial").knob({
                'change': function (v) {
                }
            });
            $('.dial').trigger(
                'configure',
                {
                    "min": 0,
                    "skin": "tron",
                    "height": 200,
                    "width": 200,
                }
            );
        };


        /** ================================== End Home Dashboard    ================================= **/


        /** ================================================================================== **/
        /** ===================================== Comptable ================================== **/
        /** ================================================================================== **/

        /**
         * Comptable - Bill page
         */
        app.initComptableBill = function () {
            // Init new date on click calendar icon on date fields
            app.initFieldsDatesOnClickIcon();

            // Submit Ajax Form
            app.submitAjaxForm('.container-bill', '.container-bill');

            // Choice only Customer field or Mode field
            app.toggleChoiceCustomerOrMode();

            $('.generation-link').each(function (index, element) {
                $(element).on('click', function (e) {
                    e.preventDefault();
                    $.ajax({
                        url: $(element).attr('data-url'),
                        method: 'GET',
                        success: function (data, textStatus, xhr) {
                            $('.container-bill').html(data);
                        },
                        error: function () {
                            app.addFlashError('Problème de chargement de la sauvegarde');
                        }
                    });
                })
            });

            // Cacher des champs suivant le mode
            $('.choice-mode input[type="radio"]').on('ifChanged', function () {
                const elementSelectorToHide = $(this).attr('data-element-to-hide');
                const elementSelectorToShow = $(this).attr('data-element-to-show');
                if (elementSelectorToHide) {
                    $(elementSelectorToHide).hide();
                }
                if (elementSelectorToShow) {
                    $(elementSelectorToShow).show();
                }
            });
        };

        /**
         * Comptable - Init Result Search Bill
         * @param {string} classAlertError
         * @param {string} actionMail
         * @param {string} actionChorus
         * @param {string} actionDownloadMultiple
         * @param {string} actionLinkPlatform
         */
        app.initResultSearchBill = function (classAlertError, actionMail, actionChorus, actionDownloadMultiple, actionLinkPlatform) {
            app.initIcheck();
            app.removeElement(classAlertError);
            app.generateFinalBillsOrFees();
            app.initOneDatatable($('#datatable-bill'), null, null, true);

            // Launch action for bills
            app.launchActionBills(actionMail, actionChorus, actionDownloadMultiple, actionLinkPlatform);

            // Select all
            app.selectAllElements();
        };

        /**
         * Comptable - Generate bills or fees
         */
        app.generateFinalBillsOrFees = function () {
            $('.btn-generate-bills').on('click', function (event) {
                event.preventDefault();

                let values = [];
                let valuesProForma = [];
                const $dataTableBill = $('#datatable-bill');
                const typeBill = $dataTableBill.attr('data-type-bill');

                // Si cas recherche normal sans coche sur bon de commande (Lorsque bon de commande est coché il n'y a pas de checkbox avec la classe cbox-bill)
                if ($('.cbox-bill').length > 0) {
                    const nodes = $dataTableBill.DataTable().column($dataTableBill.DataTable().columns(':visible').nodes().length - 1).nodes();
                    $(nodes).each(function () {
                        if ($(this).iCheck('update')[0].checked) {
                            values.push($(this).find('.cbox-bill').val());
                        }
                    });

                    const nodesProForma = $dataTableBill.DataTable().column($dataTableBill.DataTable().columns(':visible').nodes().length - 2).nodes();
                    $(nodesProForma).each(function () {
                        if ($(this).iCheck('update')[0] && $(this).iCheck('update')[0].checked) {
                            valuesProForma.push($(this).find('.cbox-bill-proforma').val());
                        }
                    });
                } else {
                    const nodesProForma = $dataTableBill.DataTable().column($dataTableBill.DataTable().columns(':visible').nodes().length - 1).nodes();
                    $(nodesProForma).each(function () {
                        if ($(this).iCheck('update')[0] && $(this).iCheck('update')[0].checked) {
                            valuesProForma.push($(this).find('.cbox-bill-proforma').val());
                            values.push($(this).find('.cbox-bill-proforma').val())
                        }
                    });
                }


                if (values.length > 0 || valuesProForma.length > 0) {
                    if (confirm('Etes-vous sûr de vouloir générer ces éléments ?')) {
                        // Display spinner
                        app.displaySpinner($('.container-bill'), '.container-bill');
                        let callBackUrl;
                        let isProgressionModalOpen = false;
                        let currentProgression = null;
                        var url = $(this).attr('href');

                        $.ajax({
                            url: url,
                            method: 'POST',
                            data: {
                                ids: values,
                                idsProForma: valuesProForma,
                                typeBill: typeBill
                            },
                            success: function (data, textStatus, xhr) {
                                if (!isProgressionModalOpen) {
                                    isProgressionModalOpen = true;
                                    app.createModal('Documents en cours de génération', '<div class="progress">\n' +
                                        '  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>\n' +
                                        '</div>', null, null, null);
                                }
                                callBackUrl = data['callBackUrl'];
                                let intervalId;
                                let waitingQ = 0;
                                const handleFinishCallBack = function () {
                                    clearInterval(intervalId);
                                    setTimeout(function () {
                                        $.ajax({
                                            url: callBackUrl,
                                            method: 'POST',
                                            data: {
                                                ids: values.concat(valuesProForma)
                                            },
                                            success: function (data, textStatus, xhr) {
                                                app.closeModal();
                                                $('.container-bill').html(data);
                                                const $reloadHistoricBloc = $('.reload-historic');
                                                $reloadHistoricBloc.html("<img src='" + $reloadHistoricBloc.attr('spinner-path') + "'>")
                                                const url = $reloadHistoricBloc.attr('data-reload-fee-url') ? $reloadHistoricBloc.attr('data-reload-fee-url') : $reloadHistoricBloc.attr('data-reload-bill-url')

                                                $.ajax({
                                                    url: url,
                                                    method: 'GET',
                                                    success: function (data, textStatus, xhr) {
                                                        $reloadHistoricBloc.html(data)
                                                    },
                                                    error: function (xhr, textStatus, codeError) {
                                                        app.addFlashError('Problème de chargement');
                                                    }
                                                })
                                            },
                                            error: function (xhr, textStatus, codeError) {
                                                app.addFlashError('Problème de chargement');
                                            }
                                        })
                                    }, 8000);
                                };


                                intervalId = setInterval(function () {
                                    $.ajax({
                                        url: data['progressionUrl'],
                                        method: 'GET',
                                        success: function (data, textStatus, xhr) {

                                            if (currentProgression !== data.current) {
                                                currentProgression = data.current;
                                            } else {

                                                waitingQ++;
                                                if (waitingQ === 5) {
                                                    // Generation bloqué
                                                    handleFinishCallBack();
                                                    app.addFlashError('Certains documents n\'ont pas pu être générés, vous pouvez les régénérer en effectuant une deuxième recherche.');
                                                }
                                            }
                                            const progressBar = $('.progress-bar');
                                            progressBar.attr('aria-valuenow', data.current);
                                            progressBar.attr('aria-valuemax', data.max);
                                            progressBar.css('width', ((data.current / data.max) * 100) + '%');
                                            $('.modal-footer').hide();
                                            if (data.isFinished === true) {
                                                handleFinishCallBack();
                                            }
                                        },
                                        error: function (xhr, textStatus, codeError) {
                                            app.addFlashError('Problème de chargement');
                                        }
                                    });

                                }, 10000);


                            },
                            error: function (xhr, textStatus, codeError) {
                                app.addFlashError('Problème de chargement');
                            }
                        });
                    }
                } else {
                    alert('Veuillez sélectionner au moins un élément');
                }
            });
        };

        /**
         * Comptable - Search - Choice only Customer field or Mode field
         */
        app.toggleChoiceCustomerOrMode = function () {
            $('.select-customer').on('change', function () {
                $('.choice-mode').iCheck('uncheck');
            });

            $('.choice-mode').on('ifChecked', function () {
                $('.select-customer').val('');
            });
        };

        /**
         * Comptable - Send mails bills
         * @param {string} actionMail
         * @param {string} actionChorus
         * @param {string} actionDownloadMultiple
         * @param {string} actionLinkPlatform
         */
        app.launchActionBills = function (actionMail, actionChorus, actionDownloadMultiple, actionLinkPlatform) {
            $('.btn-bills-action').on('click', function (event) {
                event.preventDefault();

                var isActionMail = $(this).hasClass(actionMail);
                var isActionChorus = $(this).hasClass(actionChorus);
                var isActionDownloadMultiple = $(this).hasClass(actionDownloadMultiple);
                var isActionLinkPlatform = $(this).hasClass(actionLinkPlatform);
                var values = [];

                const $dataTableBill = $('#datatable-bill');
                const nodes = $dataTableBill.DataTable().column($dataTableBill.DataTable().columns(':visible').nodes().length - 1).nodes();
                $(nodes).each(function () {
                    if ($(this).iCheck('update')[0].checked) {
                        values.push($(this).find('.cbox-bill').val());
                    }
                });

                if (values.length > 0) {
                    if (confirm('Etes-vous sûr de vouloir exécuter cette action ?')) {
                        // Display spinner
                        app.displaySpinner($('.container-spinner'), '.container-spinner');

                        var url = $(this).attr('href');
                        $.ajax({
                            url: url,
                            method: 'POST',
                            data: {
                                ids: values
                            },
                            success: function (data, textStatus, xhr) {
                                if (isActionMail) {
                                    var textToDisplay = data.nb_sends + ' e-mails seront envoyés';

                                    if (data.hasOwnProperty('nb_not_send') && data.nb_not_send !== 0) {
                                        textToDisplay += ' / ' + data.nb_not_send + ' e-mails ne seront pas envoyés.';
                                    }

                                    alert(textToDisplay);
                                } else if (isActionChorus || isActionDownloadMultiple || isActionLinkPlatform) {
                                    window.open(data.url_zip_archive, '_blank');
                                }

                                $('.container-spinner').empty();
                            },
                            error: function (xhr, textStatus, codeError) {
                                $('.container-spinner').empty();
                                app.addFlashError('Problème de chargement');
                            }
                        });
                    }
                } else {
                    alert(isActionDownloadMultiple ? 'Veuillez sélectionner des factures' : 'Aucune facture');
                }
            });
        };

        /**
         * Select all bills or fees
         */
        app.selectAllElements = function () {
            $('.select-all').on('ifChanged', function () {
                const $dataTableBill = $('#datatable-bill');
                const nodes = $dataTableBill.DataTable().column($dataTableBill.DataTable().columns(':visible').nodes().length - 1).nodes();

                $(nodes).each(function () {
                    if (!$(this).iCheck('update')[0].checked) {
                        $(this).iCheck('check');
                    } else {
                        $(this).iCheck('uncheck');
                    }
                });
            });

            $('.cbox-bill-proforma').on('ifChecked', function () {
                $(this).closest('tr').find('.cbox-bill').each(function () {
                    $(this).iCheck('check');
                })
            });

            $('.cbox-bill').on('ifUnchecked', function () {
                $(this).closest('tr').find('.cbox-bill-proforma').each(function () {
                    $(this).iCheck('uncheck');
                })
            });
        };

        /**
         * Comptable - Bons de commande
         */
        app.initBdcPage = function () {
            app.initButtonFilter($('.btn-filter'), $('.datatable-list-compta'), $('form'), 'bdc');
        };

        /**
         * Comptable - Bons de commande, champs edit
         */
        app.initOrderForm = function () {
            let selectedIds = [];
            let $checkSelectAll = $('.check-select-all');

            $('.order-form').each(function () {
                $(this).unbind();
                $(this).on('click', function () {
                    const $bdcField = $(this).closest('.fields-order-form').find('.bdc-field');
                    const $btnValidateField = $(this).closest('.fields-order-form').find('.tablebdc-save-button');
                    const url = $(this).closest('.fields-order-form').attr('data-request-url');
                    const $input = $bdcField.find('input');
                    const $bdcTextField = $(this);
                    $bdcField.attr('data-id');
                    $bdcField.removeClass('hidden');
                    $(this).addClass('hidden');
                    $input.val($(this).text() === "Cliquez pour insérer un bon de commande" ? null : $(this).text());

                    $btnValidateField.on('click', function (e) {
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: {
                                bdcValues: $input.val()
                            },
                            success: function (data) {
                                app.processAfterAjaxResponse(data);
                                $bdcField.addClass('hidden');
                                $bdcTextField.removeClass('hidden');
                                $bdcTextField.text($input.val() ? $input.val() : 'Cliquez pour insérer un bon de commande');
                            }
                        });
                        e.preventDefault();
                    });
                });
            });

            $('.check-box-bdc').each(function () {
                $(this).unbind();
                $(this).on('ifChanged', function () {
                    if (!selectedIds.indexOf($(this).val()) != -1) {
                        selectedIds.push($(this).val());
                    } else {
                        selectedIds.splice(selectedIds.indexOf($(this).val()));
                    }
                });
            });

            $checkSelectAll.unbind();
            $checkSelectAll.on('ifChanged', function () {
                var selectAll = $(this);
                $('.check-box-bdc').each(function () {
                    if (!selectAll.is(':checked')) {
                        $(this).iCheck('uncheck');
                    } else {
                        $(this).iCheck('check');
                    }
                })
            });

            $('.btn-bdc-submit').unbind();
            $('.btn-bdc-submit').on('click', function (e) {
                e.preventDefault();
                if (selectedIds.length > 0) {
                    let $btnSelectInput = $('.bdc-selected-input');
                    if ($btnSelectInput.val() !== '' && $btnSelectInput.val() !== undefined && $btnSelectInput.val() !== 'undefined') {
                        let url = $('.div-multiple-bdc').attr('data-post-url');
                        url = url.substr(0, url.length - 2) + selectedIds.join(',') + '/';
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: {
                                bdcValues: $btnSelectInput.val()
                            },
                            success: function (data) {
                                app.processAfterAjaxResponse(data);
                                $checkSelectAll.iCheck('uncheck');
                                selectedIds.length = 0;
                                $('.btn-filter').click();
                            }
                        });

                    } else {
                        app.addFlashError('Le champs bon de commande doit être remplit');
                    }
                } else {
                    app.addFlashError('Aucunes lignes sélectionnés');
                }
            });
        };

        /**
         * Init User form
         */
        app.initUserForm = function () {
            app.initHideSomething('.compte-client', '.select-customer', 'Checkbox', null, false);
        };

        /** ================================================================================== **/
        /** ===================================== Algo médecin ================================== **/
        /** ================================================================================== **/

        /**
         * Init the algo doctor element
         */
        app.initAlgoDoctor = function (leafletApiKey, data) {
            if (!data) {
                $('.map-datatable').slideUp();
                $('.algo-loader').slideDown();
            }

            if ($('#leaflet-canvas').length) {
                let leafletMap = L.map('leaflet-canvas').setView([46.3630104, 2.9846608], 6);

                $('.accordion-algo').on('click', function (e) {
                    setTimeout(function () {
                        leafletMap.invalidateSize(true);
                    }, 400);
                });

                L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    maxZoom: 18,
                    id: 'mapbox.streets',
                    accessToken: leafletApiKey
                }).addTo(leafletMap);

                app.fillMapWithData(leafletMap, data);

                app.initBtnAlgoFilter(leafletMap, leafletApiKey);
            }
        };

        /**
         * Init the btn algo filter
         */
        app.initBtnAlgoFilter = function (leafletMap, leafletApiKey) {
            let $btnAlgoFilter = $('.btn-algo-filter');
            $btnAlgoFilter.unbind();

            $btnAlgoFilter.on('click', function (e) {
                $('.map-datatable').slideUp();
                $('.algo-loader').slideDown();
                // Clear map
                leafletMap.remove();
                e.preventDefault();
                let $form = $('.form-algo');

                $.ajax({
                    type: "POST",
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    success: function (data) {
                        app.initAlgoDoctor(leafletApiKey, data);
                    }
                });

            });
        };

        /**
         * Init the algo doctor datatable
         * @param data From Ajax
         * @param $datatable
         */
        app.initAlgoDoctorDatatable = function (data, $dataTable) {
            $dataTable.dataTable().fnDestroy();
            let dataTableLangageParams = app.getConfigDatatable();
            $dataTable.dataTable({
                "data": data.datatable,
                "bLengthChange": false,
                "oLanguage": dataTableLangageParams,
                "createdRow": function (row, data, dataIndex) {
                    $(row).attr('doctor-id', data[0]);
                    $(row).addClass('doctor-id-' + data[0] + '-' + data[data.length - 1]);
                    $(row).attr('address-order', data[data.length - 1]);
                },
                "aoColumns": [{"bVisible": false}, null, null, null, null, null, null, null, null],
                "order": [[3, "asc"]],
                "pageLength": 5
            });
        };

        /**
         * Init the on click event on a datatable row
         * @param leafletMap
         * @param doctorMarkers
         */
        app.initEventOnClickDataTableAlgoDoctor = function (leafletMap, doctorMarkers) {
            let $divDatatableInfoDoctor = $('.div-datatable-info-doctor tbody');
            $divDatatableInfoDoctor.unbind();
            $divDatatableInfoDoctor.on('click', 'tr', function () {
                $('.selected').removeClass('selected');
                $(this).addClass('selected');
                let selectedMarker = doctorMarkers.get($(this).attr('doctor-id') + '-' + $(this).attr('address-order'));
                leafletMap.setView(selectedMarker._latlng, 12);
                selectedMarker.openPopup();
            });
        };

        /**
         * Put doctor markers on the map
         * @param data
         * @param leafletMap
         * @param doctorMarkerIcon
         * @param doctorMarkers
         */
        app.fillMapWithDoctorMarkers = function (data, leafletMap, doctorMarkerIcon, doctorMarkers) {
            let index = 0;

            data.leaflet.markers.forEach(function (positions) {
                positions = positions.split(',');
                let latitude = positions[0];
                let longitude = positions[1];
                let marker = L.marker([latitude, longitude], {
                    icon: doctorMarkerIcon,
                    doctorId: data.leaflet.popupData[index].doctorId,
                    addressOrder: data.leaflet.popupData[index].addressOrder,
                }).addTo(leafletMap).on('click', function (e) {
                    let doctorId = this.options.doctorId;
                    let addressOrder = this.options.addressOrder;
                    setTimeout(function () {
                        $('.doctor-id-' + doctorId + '-' + addressOrder).trigger('click');
                        app.initBtnPickDoctorAlgo();
                    }, 100);
                });

                let popupBtn = "<br>" + data.leaflet.popupData[index].btn;

                // TODO Aurait pu être généré en twig enfait ...
                marker.bindPopup(
                    "<b>" + data.leaflet.popupData[index].incrementalNumber + " "
                    + data.leaflet.popupData[index].lastname + " "
                    + data.leaflet.popupData[index].firstname +
                    "</b><br>" + data.leaflet.popupData[index].tel +
                    "<br>" + data.leaflet.popupData[index].suivi +
                    "<br>Kms réels : " + data.leaflet.popupData[index].km +
                    "<br>Temps estimés : " + data.leaflet.popupData[index].time + " mins" +
                    "<br>" + popupBtn);

                leafletMap.flyTo([latitude, longitude], 8);
                doctorMarkers.set(data.leaflet.popupData[index].doctorId + '-' + data.leaflet.popupData[index].addressOrder, marker);

                marker.on('popupopen', function (popup) {
                    app.initBtnPickDoctorAlgo();
                });

                index++;
            });
        };

        /**
         * Fill the map with the patient marker
         * @param data
         * @param leafletMap
         * @param patientMarkerIcon
         */
        app.fillMapWithPatientMarker = function (data, leafletMap, patientMarkerIcon) {
            let positionsPatient = data.leaflet.aim.marker.split(',');
            let latitudePatient = positionsPatient[0];
            let longitudePatient = positionsPatient[1];
            let markerPatient = L.marker([latitudePatient, longitudePatient], {icon: patientMarkerIcon}).addTo(leafletMap).on('click', function (e) {
                leafletMap.setView(e.target._latlng, 12);
            });

            markerPatient.bindPopup(
                "<b>" + data.leaflet.aim.fullname + "</b>");
        };

        /**
         * Fill the map with the datas
         * @param leafletMap
         * @param data
         */
        app.fillMapWithData = function (leafletMap, data) {
            let $dataTable = $('#datatable-algo-doctor');
            let doctorMarkers = new Map();

            if (data) {
                app.handleAjaxDataForFillingMap(data, $dataTable, leafletMap, doctorMarkers);
            } else {
                $.ajax({
                    url: $('.algo-doctor').attr('data-url'),
                    method: 'GET',
                    success: function (data, textStatus, xhr) {
                        app.handleAjaxDataForFillingMap(data, $dataTable, leafletMap, doctorMarkers);
                    },
                    error: function (xhr, textStatus, codeError) {
                        app.addFlashError('Problème de chargement');
                    }
                });
            }
        };

        /**
         * Fill map with data after an ajax request
         * @param data
         * @param $dataTable
         * @param leafletMap
         * @param doctorMarkers
         */
        app.handleAjaxDataForFillingMap = function (data, $dataTable, leafletMap, doctorMarkers) {

            app.initAlgoDoctorDatatable(data, $dataTable);
            app.initEventOnClickDataTableAlgoDoctor(leafletMap, doctorMarkers);

            let doctorMarkerIcon = L.ExtraMarkers.icon({
                icon: 'fa-user-md',
                markerColor: 'green',
                shape: 'square',
                prefix: 'fa',
            });

            let patientMarker = L.ExtraMarkers.icon({
                icon: 'fa-crosshairs',
                markerColor: 'red',
                shape: 'square',
                prefix: 'fa'
            });

            app.fillMapWithDoctorMarkers(data, leafletMap, doctorMarkerIcon, doctorMarkers);
            app.fillMapWithPatientMarker(data, leafletMap, patientMarker);

            $('.algo-loader').slideUp();
            $('.map-datatable').slideDown();

            leafletMap.invalidateSize(true);
        };

        /**
         * Init the pick doctor buttons
         */
        app.initBtnPickDoctorAlgo = function () {
            let $selectDoctor = $('.select-doctor');
            let $inputCalculatedKm = $('.medical-control-calculated-km');

            $('.btn-pick-doctor').each(function (e) {
                $(this).unbind();
                $(this).on('click', function (e) {
                    $selectDoctor.val($(this).attr('doctor-id'));
                    $selectDoctor.trigger('change.select2');
                    $inputCalculatedKm.val(parseFloat($('.selected').find('td').find('span').html()));
                    $(this).slideUp();
                });
            })
        };

        /**
         * Display a modal in order to inform the user that someone is already working on this medicalcontrol
         */
        app.displayLockModal = function (user) {
            app.createModal("Information", user + " est déjà en train d\'accéder à cet acte.", null, "<button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">Fermer</button>");
        };

        /**
         * Show the register form modal when loaded
         */
        app.createRegisterModalForm = function (html) {
            app.createModal("Créer un compte Client", html, true, "<button type=\"submit\" class=\"btn btn-success\">Valider</button>");
            $('.btn-success').on('click', function (e) {
                e.preventDefault();
                let $form = $('.form-register');
                $.ajax({
                    type: "POST",
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    success: function (data) {
                        app.processAfterAjaxResponse(data);
                    }
                });
            });
        };

        /**
         * Init Register Form
         */
        app.initRegisterForm = function () {
            $('.select-system-secu').select2({placeholder: "Rég. sécu"});
            $('.select2-container').css('width', '100%');
            var checkboxSecondContact = $('.second-contact-different');
            checkboxSecondContact.on('ifChanged', function (event) {
                app.initRegisterSecondContact($(this));
            });
            app.initRegisterSecondContact(checkboxSecondContact);

            app.initCommonNewCustomer();
        };

        /**
         * Js du formulaire de création client (côté register et admin Medicat)
         */
        app.initCommonNewCustomer = function () {
            // Remplissage du champ e-mail contact à partir de l'e-mail client
            $('.email-customer').on('keyup', function () {
                $('.email-contact-main').val($(this).val());
            });

            // MP-402 : Code de parrainage
            let $originCustomer = $('.origin-customer');
            let $sponsorshipCode = $('.sponsorship-code');
            let $sponsorshipCodeContainer = $sponsorshipCode.closest('.form-group');
            $originCustomer.on('change', function() {
                console.log($(this).find(':selected').data('hasSponsorshipCode'));
                if ($(this).find(':selected').data('hasSponsorshipCode') === 1) {
                    $sponsorshipCodeContainer.show();
                } else {
                    $sponsorshipCodeContainer.hide();
                    $sponsorshipCode.val('');
                }
            });
            $originCustomer.change();
        };

        /**
         * Init Register Second Contact
         * @param element
         */
        app.initRegisterSecondContact = function (element) {
            var $bloc = $('.bloc-second-contact');
            var cssClass = 'hide-element';
            if (element.is(':checked')) {
                $bloc.removeClass(cssClass);
                $bloc.find('input, select').each(function () {
                    $(this).attr('required', 'required');
                    $(this).closest('.fl-wrap').addClass('fl-is-required');
                });
            } else {
                $bloc.addClass(cssClass);
                $bloc.find('input, select').each(function () {
                    $(this).removeAttr('required');
                    $(this).closest('.fl-wrap').removeClass('fl-is-required');
                });
            }
        };

        app.initAccountingBook = function () {
            app.initButtonFilter($('.btn-filter-customer'), $('#datatable-customer'), $('.form-accounting-book'), 'comptable-book');
        };

        app.showAccountBookRowDetails = function () {
            $('.show-row-details').unbind();
            $('.show-row-details').on('click', function () {

                var thisNameButton = $(this);

                var mainRowSelector = $(this).parent('td').parent('tr');
                var nextRow = mainRowSelector.next('tr');

                if (nextRow.hasClass('details-row') && !nextRow.is(':hidden')) {

                    nextRow.hide();
                    thisNameButton.children('i.fa').removeClass('fa-minus-circle');
                    thisNameButton.children('i.fa').addClass('fa-plus-circle');

                } else {

                    if (nextRow.hasClass('details-row') && nextRow.is(':hidden')) {

                        thisNameButton.children('i.fa').removeClass('fa-plus-circle');
                        thisNameButton.children('i.fa').addClass('fa-minus-circle');
                        nextRow.show();

                    } else {

                        $.post(
                            $(this).data('url'),
                            {
                                'row_classes': mainRowSelector.attr('class')
                            },
                            function (data) {
                                thisNameButton.children('i.fa').removeClass('fa-plus-circle');
                                thisNameButton.children('i.fa').addClass('fa-minus-circle');
                                mainRowSelector.after(data);
                            },
                            'html'
                        );
                    }
                }
            });
        };

        app.saveBillPaymentDate = function () {

            $('.btn-save-payment-date').on('click', function () {

                var dateInput = $(this).closest('.payment-date').find('input.payment-date-picker');

                $.post(
                    dateInput.data('url'),
                    {
                        'date': dateInput.val()
                    },
                    function (data) {
                        app.processAfterAjaxResponse(data);
                    },
                    'json'
                );
            });
        };

        app.initDoctorAccountingBook = function () {
            app.initButtonFilter($('.btn-filter-customer'), $('#datatable-doctor'), $('.form-accounting-book'), 'comptable-book', false);
        };

        app.saveFeePaymentTracking = function () {
            $('.btn-save-payment-tracking').on('click', function () {

                var paymentTrackingInput = $(this).prev('input');

                $.post(
                    paymentTrackingInput.data('url'),
                    {
                        'payment_tracking': paymentTrackingInput.val()
                    },
                    function (data) {
                        app.processAfterAjaxResponse(data);
                    },
                    'json'
                );
            });
        };
    }
    ()
);